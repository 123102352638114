import React, {useEffect, useState} from "react"
import {ToastContainer, toast} from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {Slide} from "react-toastify"
import {withRouter} from "react-router-dom"
import PropTypes from "prop-types"
import Select from "react-select"
//i18n
import {withTranslation} from "react-i18next"
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import API from "../../api"
import {Button, Col, Form, Input, Label, Row} from "reactstrap"
import getDay from "date-fns/getDay"
import Switch from "react-switch";

const AddParametrageDs = props => {
    const [date, setDate] = useState("")
    const [dateFin, setDateFin] = useState("");
    const [semestre, setSemestre] = useState([
        {value: 1, label: "Semestre 1"},
        {value: 2, label: "Semestre 2"},
    ])
    const [etat, setEtat] = useState(1)
    const [selectSemestre, setSelectSemestre] = useState("")
    var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
    var annee_id = userAuthScolarite.user.annee_id
    var user_id = userAuthScolarite.user.id
    useEffect(async () => {

    }, [])


    const save = async () => {

        // convert date début
        let dateRatt = date
        let month = "" + (dateRatt.getMonth() + 1)
        let day = "" + dateRatt.getDate()
        let year = dateRatt.getFullYear()
        if (month.length < 2) month = "0" + month
        if (day.length < 2) day = "0" + day
        let convertDate = [year, month, day].join("-")
        // convert date fin
        let dateFinConv = dateFin
        let monthFin = "" + (dateFinConv.getMonth() + 1)
        let dayFin = "" + dateFinConv.getDate()
        let yearFin = dateFinConv.getFullYear()
        if (monthFin.length < 2) monthFin = "0" + monthFin
        if (dayFin.length < 2) dayFin = "0" + dayFin
        let convertDateFin = [yearFin, monthFin, dayFin].join("-")
        //
        const res = await API.post("ds_parametrage/add", {
            date_debut: convertDate,
            date_fin:convertDateFin,
            semestre_id: selectSemestre.value,
            active:etat,
            annee_id,
            user_id
        })
            .then(res => {
                props.setSection(3)
            })
            .catch(() => {
                toast.error("Problème lors de l'insertion !", {
                    containerId: "A",
                })
            })

    }
    return (
        <React.Fragment>
            <Row>
                <Form>
                    <Row>
                        <Col lg="6">
                            <div className="mb-3">
                                <Label for="basicpill-firstname-input1">
                                    {props.t("Semestre")}
                                </Label>
                                <Select
                                    options={semestre}
                                    isSearchable={true}
                                    value={selectSemestre}
                                    onChange={e => setSelectSemestre(e)}
                                />
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="6">
                            <div className="mb-3">
                                <Label for="basicpill-firstname-input1">
                                    {props.t("Date début")}
                                    <span style={{color: "red", paddingLeft: "5px"}}>*</span>
                                </Label>
                                <DatePicker
                                    className="form-control ddate"
                                    selected={date}
                                    onChange={e => setDate(e)}
                                    dateFormat="dd/MM/yyyy"
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="6">
                            <div className="mb-3">
                                <Label for="basicpill-firstname-input1">
                                    {props.t("Date fin")}
                                    <span style={{color: "red", paddingLeft: "5px"}}>*</span>
                                </Label>
                                <DatePicker
                                    className="form-control ddate"
                                    selected={dateFin}
                                    onChange={e => setDateFin(e)}
                                    dateFormat="dd/MM/yyyy"
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <div className="mb-3">
                                <Label className="d-block mb-3">Etat :</Label>
                                <div className="form-check form-check-inline">
                                    <Input
                                        type="radio"
                                        id="customRadioInline1"
                                        name="customRadioInline1"
                                        className="form-check-input"
                                        checked={etat === 1}
                                        onChange={e => setEtat(1)}
                                    />
                                    <Label
                                        className="form-check-label"
                                        htmlFor="customRadioInline1"
                                    >
                                        Activé
                                    </Label>
                                </div>
                                &nbsp;
                                <div className="form-check form-check-inline">
                                    <Input
                                        type="radio"
                                        id="customRadioInline2"
                                        name="customRadioInline1"
                                        className="form-check-input"
                                        checked={etat === 2}
                                        onChange={e => setEtat(2)}
                                    />
                                    <Label
                                        className="form-check-label"
                                        htmlFor="customRadioInline2"
                                    >
                                        Désactivé
                                    </Label>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Form>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    {" "}
                    <Col lg="6">
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                            className="text-center mt-4"
                        >
                            <Button
                                type="button"
                                color="warning"
                                className="btn btn-warning  mb-2 me-2"
                                onClick={props.back}
                            >
                                {props.t("Annuler")}
                            </Button>
                        </div>
                    </Col>
                    <Col lg="6">
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-start",
                            }}
                            className="text-center mt-4"
                        >
                            <button type="button" className="btn btn-primary " onClick={save}>
                                {props.t("Confirmer")}
                            </button>
                        </div>
                    </Col>
                </div>
            </Row>
            <ToastContainer
                transition={Slide}
                enableMultiContainer
                containerId={"A"}
                position={toast.POSITION.TOP_RIGHT}
                autoClose={2500}
            />
        </React.Fragment>
    )
}
export default withRouter(withTranslation()(AddParametrageDs))
AddParametrageDs.propTypes = {
    history: PropTypes.object,
    back: PropTypes.object,
    setSection: PropTypes.object,
    t: PropTypes.any,
}
