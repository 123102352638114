import React, {useEffect, useState} from "react"
import MetaTags from "react-meta-tags"
import {Container, Card, CardBody, Nav, NavItem, NavLink} from "reactstrap"
import classnames from "classnames"
import {withRouter} from "react-router"
import {withTranslation} from "react-i18next"
import PropTypes from "prop-types"
import DataTableParametrageExamen from "./DataTableParametrageExamen";
import AddParametrageExamen from "./AddParametrageExamen";

const ParametrageExamen = props => {
    const [section, setSection] = useState(1)
    const [add, setAdd] = useState(0);
    const [cloture, setCloture] = useState(0);
    useEffect(async () => {
        var cloture_annee = JSON.parse(localStorage.getItem("cloture"));
        setCloture(cloture_annee)
        const userAuth = JSON.parse(localStorage.getItem('UserMenu'));
        const typeArticleSubMenu = userAuth.find(user =>
            user.sous_menu.some(subMenu => subMenu.sous_menu === "Paramétrage de date d'examen")
        );
        setAdd(typeArticleSubMenu.sous_menu[0].add)

    }, [])
    const back = () => {
        setSection(1)
    }

    const renderBlock = section => {
        if (section === 1) {
            return <DataTableParametrageExamen/>
        } else if (section === 2) {
            return <AddParametrageExamen setSection={setSection} back={back}/>
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{props.t("Parametrage d'examen")}</title>
                </MetaTags>
                <Container fluid>
                    <div>
                        <Nav tabs>

                            <NavItem>
                                <NavLink
                                    style={{cursor: "pointer"}}
                                    className={classnames({
                                        active: section === 1,
                                    })}
                                    onClick={() => {
                                        setSection(1)
                                    }}
                                >
                                    {props.t("Paramétrage de date d'examen")}
                                </NavLink>
                            </NavItem>
                            {cloture !== 1 ? (
                                add === 1 ? (
                                    <NavItem>
                                        <NavLink
                                            style={{cursor: "pointer"}}
                                            className={classnames({
                                                active: section === 2,
                                            })}
                                            onClick={() => {
                                                setSection(2)
                                            }}
                                        >
                                            {props.t("Ajouter paramétrage de date d'examen")}
                                        </NavLink>
                                    </NavItem>
                                ) : null
                            ) : null}
                        </Nav>
                        <Card>
                            <CardBody>{renderBlock(section)}</CardBody>
                        </Card>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    )
}

ParametrageExamen.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
}

export default withRouter(withTranslation()(ParametrageExamen))
