import React, {Fragment, useEffect, useState} from "react"
import {Button, Col, Form, Input, Label, Modal, ModalBody, ModalHeader, Row, Spinner, Table} from "reactstrap"
import PropTypes from "prop-types"
import {ToastContainer, toast} from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {Slide} from "react-toastify"
import API from "../../api"
import Select from "react-select";
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import getDay from "date-fns/getDay"

const AddSeanceExamen = props => {
    const [loading, setLoading] = useState(false)
    const [selectSemestre, setSelectSemestre] = useState("")
    const [niveau, setNiveau] = useState([])
    const [selectNiveau, setSelectNiveau] = useState("")
    const [specialite, setSpecialite] = useState([])
    const [selectSpecialite, setSelectSpecialite] = useState("")
    const [arrayQuart, setArrayQuart] = useState([]);
    const [arrayJpurs, setArrayJpurs] = useState([]);
    const [modal, setModal] = useState(false)
    const [id, setId] = useState("")
    const [jourId, setJourId] = useState("");
    const [quartId, setQuartId] = useState("")
    const [matiere, setMatiere] = useState([]);
    const [selectMatiere, setSelectMatiere] = useState("");
    const [enseignant, setEnseignant] = useState([]);
    const [selectEnseignant, setSelectEnseignant] = useState("");
    const [dure, setDure] = useState([
        {value: 1, label: "1h", key: "1"},
        {value: 2, label: "1h:30", key: "1.5"},
        {value: 3, label: "2h", key: "2"},
        {value: 4, label: "2h:30", key: "2.5"},
        {value: 4, label: "3h", key: "3"},
        {value: 5, label: "3h:30", key: "3.5"},
        {value: 6, label: "4h", key: "4"},
    ])
    const [selectDure, setSelectDure] = useState("");
    const [array, setArray] = useState([
        {
            semestre_id: "",
            niveau_id: "",
            specialite_id: "",
            matiere_id: "",
            date: "",
            dure: "",
            responsable_id: "",
            nb_group: ""
        },
    ])
    const [modalLigne, setModalLigne] = useState(false)
    const [indexLigne, setIndexLigne] = useState("")
    const [reste, setReste] = useState("")
    const [msgReste, setMsgReste] = useState("");
    const [nbGroup, setNbGroup] = useState("");
    const [modalDetail, setModalDetail] = useState(false);
    const [modalConfirmerGroupeMemeSeance, setModalConfirmerGroupeMemeSeance] = useState(false);
    const [modalConfirmerGroupeMemeSeanceSave, setModalConfirmerGroupeMemeSeanceSave] = useState(false);
    const [modalErreur, setModalErreur] = useState(false);
    const [dateDetail, setDateDetail] = useState("");
    const [arrayDetail, setArrayDetail] = useState([]);
    const [specialiteId, setSpecialiteId] = useState("");
    const [matiereId, setMatiereId] = useState("");
    const [niveauId, setNiveauId] = useState("");
    const [dateId, setDateId] = useState("")
    const [modalDelete, setModalDelete] = useState(false);
    const [title, setTitle] = useState("")
    const [msg, setMsg] = useState("")
    const [minDate, setMinDate] = useState(new Date())
    const [maxDate, setMaxDate] = useState(new Date())
    const [date, setDate] = useState("");
    const [etat, setEtat] = useState(0);
    var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
    var annee_id = userAuthScolarite.user.annee_id
    var user_id = userAuthScolarite.user.id
    useEffect(async () => {
        const res = await API.get("niveau/select").then(res => {
            setNiveau(res.data.Niveau)
        })
        const resEnseignant = await API.get("enseignant/select").then(resEnseignant => {
            setEnseignant(resEnseignant.data.select_enseignant)
        })
        const resGetQuart = await API.post("salle_examen/getByQuart", {
            annee_id,
            semestre_id: props.semestre.value
        }).then(resGetQuart => {
            setArrayQuart(resGetQuart.data.emploi[0].quarts)
            setArrayJpurs(resGetQuart.data.emploi[0].seances)
        })
        API.post("examen_parametrage/getBy_annee_semestre", {
            semestre_id: props.semestre.value,annee_id
        }).then(resAnnee => {
            setMinDate(new Date(resAnnee.data.params.date_debut))
            setMaxDate(new Date(resAnnee.data.params.date_fin))
        })
        setLoading(true)
    }, [props.semestre])
    const save = async () => {
        let hasError = false;
        array.forEach((currentRow, index) => {
            const requiredFields = [
                { field: 'semestre_id', message: '⛔ Semestre obligatoire !' },
                { field: 'niveau_id', message: '⛔ Niveau obligatoire !' },
                { field: 'specialite_id', message: '⛔ Spécialité obligatoire !' },
                { field: 'matiere_id', message: '⛔ Matière obligatoire !' },
                { field: 'dure', message: '⛔ Durée obligatoire !' },
                { field: 'responsable_id', message: '⛔ Enseignant obligatoire !' }
            ];
            for (let { field, message } of requiredFields) {
                if (!currentRow[field]) {
                    toggleErreur(`Champs Obligatoire - Ligne ${index + 1}`, message);
                    hasError = true;
                    return;
                }
            }
        });
        if (!hasError) {
            API.post("seance_examen/updateStateSalles", {
                array: array,
                quart_id: quartId,
                jour_id: jourId,
                annee_id
            }).then(res => {
                if (res.data.errNum === 301 || res.data.errNum === "301") {
                    return toggleErreur("Faites attention", "⛔ " + res.data.msg)
                } else if(res.data.errNum === 302 || res.data.errNum === "302") {
                    toggleConfirmerGroupeMemeSeanceSave(array)
                    setMsg(res.data.msg)
                }
                else{
                    API.post("seance_examen/save", {
                        quart_id: quartId,
                        jour_id: jourId,
                        array: array,
                        annee_id,
                        user_id,
                        with_confirmation:0
                    }).then(res => {
                        if (res.data.errNum === "301") {
                            toggleErreur("Faites attention", "⛔ " + res.data.msg)
                        }else {
                            setModal(false)
                            API.post("salle_examen/getByQuart", {
                                annee_id,
                                semestre_id: props.semestre.value
                            }).then(resGetQuart => {
                                setArrayQuart(resGetQuart.data.emploi[0].quarts)
                                setArrayJpurs(resGetQuart.data.emploi[0].seances)
                            })
                            setLoading(true)
                        }
                    })
                }
            })
        }
    }
    const toggle = (el, elem, index,date) => {
        setModal(!modal);
        setId(id);
        setQuartId(el);
        setJourId(elem);
        setMsgReste("")
        setReste("")
        setDate(date)
        const updatedRow = {
            ...array[index],
            niveau_id: "",
            specialite_id: "",
            matiere_id: "",
            dure: "",
            responsable_id: "",
            nb_group: "",
            date:date,
        };
        setArray([updatedRow]);
    };
    const toggleDetail = (el, elem,date) => {
        setModalDetail(!modalDetail);
        if(modalDetail !== true){
            API.post("salle_examen/DetailByQuarts", {
                jour_id: elem,
                semestre_id: props.semestre.value,
                quart_id:el,
                date: date,
                annee_id
            }).then(res => {
                setArrayDetail(res.data.details)
            })

            setId(id);
            setQuartId(el);
            setJourId(elem);
            setDateDetail(date)
        }

    };
    const toggleDeleteSeance = async () => {
        const res = await API.post("seance_examen/delete_by_specialite_niveau_date_matiere", {
            date: dateId,
            matiere_id:matiereId,
            specialite_id:specialiteId,
            niveau_id:niveauId,
            semestre_id:props.semestre.value,
            annee_id,user_id
        }).then(res => {
            if (res.data.errNum === 301 || res.data.errNum ==="301") {
                toggleErreur("Faites attention","⛔ "+res.data.msg)

            } else {
                setModalDelete(false)
                API.post("salle_examen/DetailByQuarts", {
                    jour_id: jourId,
                    semestre_id: props.semestre.value,
                    quart_id:quartId,
                    date: dateId,
                    annee_id
                }).then(res => {
                    setArrayDetail(res.data.details)
                    if ( res.data.details && (Array.isArray(res.data.details) ? res.data.details.length === 0 : Object.keys(res.data.details).length === 0)) {
                        setModalDetail(false)
                    }else{
                        setModalDetail(true)
                    }
                    API.post("salle_examen/getByQuart", {
                        annee_id,
                        semestre_id: props.semestre.value
                    }).then(resGetQuart => {
                        setArrayQuart(resGetQuart.data.emploi[0].quarts)
                        setArrayJpurs(resGetQuart.data.emploi[0].seances)
                        setLoading(true)
                    })
                })
            }
        })
    }
    const toggleSeance = async () => {
        API.post("seance_examen/updateStateSalles", {
            array: array,
            quart_id: quartId,
            jour_id: jourId,
            annee_id,
            with_confirmation:1
        }).then(res => {
            if (res.data.errNum === 301 || res.data.errNum === "301") {
                toggleErreur("Faites attention","⛔ " + res.data.msg)
                setModalConfirmerGroupeMemeSeance(true)
            } else {
                setModalConfirmerGroupeMemeSeance(false)
                setReste(res.data.nb_salle_libre)
                setMsgReste(res.data.msg)
                let element = {
                    semestre_id: "",
                    niveau_id: "",
                    specialite_id: "",
                    matiere_id: "",
                    date:date,
                    dure: "",
                    responsable_id: ""
                }
                setArray([...array, element])

            }
        })
    }
    const toggleSeanceSave = async () => {
        API.post("seance_examen/save", {
            quart_id: quartId,
            jour_id: jourId,
            array: array,
            annee_id,
            user_id,
            with_confirmation:1
        }).then(res => {
            if (res.data.errNum === "301") {
                setModalConfirmerGroupeMemeSeanceSave(true)
                toggleErreur("Faites attention", "⛔ " + res.data.msg)
            }else {
                setModalConfirmerGroupeMemeSeanceSave(false)
                setModal(false)
                API.post("salle_examen/getByQuart", {
                    annee_id,
                    semestre_id: props.semestre.value
                }).then(resGetQuart => {
                    setArrayQuart(resGetQuart.data.emploi[0].quarts)
                    setArrayJpurs(resGetQuart.data.emploi[0].seances)
                })
                setLoading(true)
            }
        })
    }

    const toggleDelete = (specialite_id,matiere_id,niveau_id,date) => {
        setModalDelete(!modalDelete);
        setMatiereId(matiere_id)
        setSpecialiteId(specialite_id)
        setNiveauId(niveau_id)
        setDateId(date)
     };
    const getSpecialite = async (e, index) => {
        setArray(
            array.map((el, id) =>
                id === index ? Object.assign(el, {
                    niveau_id: e,
                    specialite_id: "",
                    matiere_id: "",
                    date:date,
                    dure: "",
                    responsable_id: "",
                    nb_group: ""
                }) : el
            )
        )
        setSelectNiveau(e)
        const res = await API.post("group/get_list_spec", {
            niveau_id: e.value,
            semestre_id: [selectSemestre],
        }).then(res => {
            setSpecialite(res.data.Groups)
        })
    }
    const getMatiere = async (e, index) => {
        setSelectSpecialite(e)

        const res = await API.post("matiere/get_non_passe_by_niveaux_semestre_specialite", {
            specialite_id: e.value,
            niveau_id: selectNiveau.value,
            semestre_id: selectSemestre.value,
            annee_id
        }).then(res => {
            setMatiere(res.data.Matiere)
        })
        API.post("groupe_examen/getNbGroup_BySemNivSpec", {
            specialite_id: e.value,
            niveau_id: selectNiveau.value,
            semestre_id: selectSemestre.value,
            annee_id
        }).then(res => {
            setNbGroup(res.data.nb_groups)
            setArray(
                array.map((el, id) =>
                    id === index ? Object.assign(el, {
                        specialite_id: e,
                        matiere_id: "",
                        date:date,
                        dure: "",
                        responsable_id: "",
                        nb_group: res.data.nb_groups
                    }) : el
                )
            )
        })

    }
    const getMatiere1 = async (e, index) => {
        setSelectMatiere(e)
        API.post("enseignant/get_resposable_by_matiere", {
            matiere_id: e.value,
            annee_id
        }).then(res => {
            setArray(
                array.map((el, id) =>
                    id === index ? Object.assign(el, {
                        matiere_id: e,
                        dure: "",
                        responsable_id: res.data.ens,
                        date:date
                    }) : el
                )
            )
        })


    }
    const getDure = async (e, index) => {
        setSelectDure(e)
        setArray(
            array.map((el, id) =>
                id === index ? Object.assign(el, {
                    date:date,
                    dure: e,
                }) : el
            )
        )
    }
    const getEnseignant = async (e, index) => {
        setSelectEnseignant(e)
        setArray(
            array.map((el, id) =>
                id === index ? Object.assign(el, {
                    responsable_id: e
                }) : el
            )
        )

    }
    const toggleLigne = (indexOfLigne) => {
        setModalLigne(!modalLigne);
        setIndexLigne(indexOfLigne);
    };
    const toggleConfirmerGroupeMemeSeance = (indexOfLigne) => {
        setModalConfirmerGroupeMemeSeance(!modalConfirmerGroupeMemeSeance);
        if(modalConfirmerGroupeMemeSeance !== true){
            setIndexLigne(indexOfLigne);
        }
    };
    const toggleConfirmerGroupeMemeSeanceSave = (indexOfLigne) => {
        setModalConfirmerGroupeMemeSeanceSave(!modalConfirmerGroupeMemeSeanceSave);
        if(modalConfirmerGroupeMemeSeanceSave !== true){
            setIndexLigne(indexOfLigne);
        }
    };
    const addLigne = (indexOfLigne) => {
        const currentRow = array[indexOfLigne];
        if (!currentRow.semestre_id) {
            return toggleErreur("Champs Obligatoire","⛔ Semestre obligatoire !")
        }
        if (!currentRow.niveau_id) {
            return toggleErreur("Champs Obligatoire","⛔ Niveau obligatoire !")
        }
        if (!currentRow.specialite_id) {
            return toggleErreur("Champs Obligatoire","⛔ Spécialité obligatoire !")
        }
        if (!currentRow.matiere_id) {
            return toggleErreur("Champs Obligatoire","⛔ Matière obligatoire !")
        }
        if (!currentRow.dure) {
            return toggleErreur("Champs Obligatoire","⛔ Durée obligatoire !")
        }
        if (!currentRow.responsable_id) {
            return toggleErreur("Champs Obligatoire","⛔ Enseignant obligatoire !")
        }
        API.post("seance_examen/updateStateSalles", {
            array: array,
            quart_id: quartId,
            jour_id: jourId,
            annee_id
        }).then(res => {
            if (res.data.errNum === 301 || res.data.errNum === "301") {
                return toggleErreur("Faites attention","⛔ " + res.data.msg)
            } else if(res.data.errNum === 302 || res.data.errNum === "301") {
                toggleConfirmerGroupeMemeSeance(indexOfLigne)
                setMsg(res.data.msg)
            }else{
                setReste(res.data.nb_salle_libre)
                setMsgReste(res.data.msg)
                let element = {
                    semestre_id: "",
                    niveau_id: "",
                    specialite_id: "",
                    matiere_id: "",
                    date:date,
                    dure: "",
                    responsable_id: ""
                }
                setArray([...array, element])
            }
        })

    }
    const toggleDeleteLigne = () => {
        array.splice(indexLigne, 1)
        setModalLigne(false)
        API.post("seance_examen/updateStateSalles", {
            array: array,
            quart_id: quartId,
            jour_id: jourId,
            annee_id
        }).then(res => {
            if (res.data.errNum === 301 || res.data.errNum === "301") {
                toggleErreur("Faites attention","⛔ " + res.data.msg)

            } else {
                setReste(res.data.nb_salle_libre)
                setMsgReste(res.data.msg)
            }
        })
    }
    const getSemestre = async (e, index) => {
        setSelectSemestre(e)
        setArray(
            array.map((el, id) =>
                id === index ? Object.assign(el, {
                    date:date,
                    semestre_id: e,
                    niveau_id: "",
                    specialite_id: "",
                    matiere_id: "",
                    dure: "",
                    responsable_id: ""
                }) : el
            )
        )

    }
    const toggleErreur = async (title,msg) => {
        setModalErreur(!modalErreur)
        if(modalErreur !== true){
            setTitle(title)
            setMsg(msg)
        }
    }
    return (
        <React.Fragment>
            {loading ? (
                <Row>
                <Form>
                    <div>
                        <div className="table-responsive mt-4">
                            <table className="table table-bordered text-center">
                                <thead className="table table-light">
                                <tr>
                                    <th></th>
                                    {arrayQuart.map(el => (
                                        <th
                                            key={el.id_quart}
                                            style={{whiteSpace: "nowrap"}}
                                        >
                                            {el.label_quart}
                                        </th>
                                    ))}
                                </tr>
                                </thead>
                                {arrayJpurs.map((elem, index) => (
                                    <tr key={index}>
                                        <td style={{fontWeight: "bold"}}>
                                            {elem.label_jour}
                                        </td>
                                        {elem.jours.map((element, indice) =>
                                            <td
                                                key={indice}
                                                style={{whiteSpace: "nowrap"}}
                                            >
                                                {element.options.map((el,ind)=> (
                                                    el['reserve'] !== 0 ? (
                                                        <Fragment>
                                                            <div style={{padding: "5% 0%"}}>
                                                                <p>Il vous reste que <span style={{color:"red"}}>{el['reserve']} </span>salle de <span
                                                                    style={{color:"red"}}>{el['tous_salles']}</span>  <br/>dans le date <span style={{color:"red"}}>{el['date']}</span></p>
                                                            </div>
                                                            <div style={{padding: "5% 0%"}}>
                                                                <button
                                                                    onClick={() =>
                                                                        toggleDetail(
                                                                            element.id_quart,
                                                                            elem.id_jour,
                                                                            el.date
                                                                        )
                                                                    }
                                                                    type="button"
                                                                    className="btn btn-secondary btn-link btn-just-icon btn-sm text-white"
                                                                    title="Voir le détail"
                                                                >
                                                                    <i className="fas fa-eye"></i>
                                                                </button>
                                                            </div>
                                                        </Fragment>

                                                    ) : null
                                                ))}


                                                <div style={{padding: "5% 0%"}}>
                                                    <button
                                                        onClick={() =>
                                                            toggle(
                                                                element.id_quart,
                                                                elem.id_jour, index,elem.date
                                                            )
                                                        }
                                                        type="button"
                                                        className="btn btn-info btn-link btn-just-icon btn-sm text-white"
                                                        title="Ajouter Séance"
                                                    >
                                                        <i className="fas fa-plus"></i>
                                                    </button>
                                                </div>

                                            </td>
                                        )}
                                    </tr>
                                ))}
                            </table>
                        </div>
                    </div>
                </Form>

            </Row>
            ) : (
                <div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        {" "}
                        <Spinner type="grow" className="ms-6" color="primary"/>
                    </div>
                    <h4
                        style={{textAlign: "center", marginTop: "2%"}}
                        className="ms-6"
                    >
                        {" "}
                        {"Chargement"} ...{" "}
                    </h4>
                </div>
            )}
            <Modal size="xl" isOpen={modal} toggle={toggle} centered={true}>
                <ModalHeader
                    style={{width: "100% !important", textAlign: "center !important"}}
                    toggle={toggle}
                    tag="h4"
                >
                    {"Ajouter un calendrier d'examen"}
                </ModalHeader>
                <ModalBody>
                    <div
                        style={{
                            fontFamily: "Montserrat, sans-serif",
                            FontSize: "14px",
                            FontWeight: "700",
                            LineHeight: "18.375px",
                        }}
                    >
                        {array.map((element, index) => (
                            <Fragment key={index}>
                                {msgReste !== "" ? (
                                    <h4>{msgReste}</h4>
                                ) : null}
                                <Row>
                                    <Col md={3}>
                                        <div className="mb-3">
                                            <Label for="basicpill-firstname-input1">Semestre:</Label>
                                            <Select
                                                options={[props.semestre]}
                                                isSearchable={true}
                                                onChange={e => getSemestre(e, index)}
                                                value={element.semestre_id}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg="3">
                                        <div className="mb-3">
                                            <Label for="basicpill-firstname-input1">Niveau</Label>
                                            <Select
                                                options={niveau}
                                                isSearchable={true}
                                                onChange={e => getSpecialite(e, index)}
                                                value={element.niveau_id}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg="3">
                                        <div className="mb-3">
                                            <Label for="basicpill-firstname-input1">Spécialité</Label>
                                            <Select
                                                options={specialite}
                                                isSearchable={true}
                                                onChange={e => getMatiere(e, index)}
                                                value={element.specialite_id}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg="3">
                                        <div className="mb-3">
                                            <Label for="basicpill-firstname-input1">Matière</Label>
                                            <Select
                                                options={matiere}
                                                isSearchable={true}
                                                onChange={e => getMatiere1(e, index)}
                                                value={element.matiere_id}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="3">
                                        <div className="mb-3">
                                            <Label for="basicpill-firstname-input1">{"Date d'examen"}</Label>
                                            <Input
                                                lg="3"
                                                className="form-control"
                                                placeholder="Date d'examen"
                                                type="text"
                                                disabled={true}
                                                value={date}
                                            />
                                            {/*<DatePicker*/}
                                            {/*    className="form-control ddate"*/}
                                            {/*    minDate={minDate}*/}
                                            {/*    maxDate={maxDate}*/}
                                            {/*    selected={array[index]?.date ? new Date(array[index].date) : null}*/}
                                            {/*    onChange={(date) => getDate(date, index)}*/}
                                            {/*    dateFormat="yyyy-MM-dd"*/}
                                            {/*    filterDate={isWeekday}*/}
                                            {/*/>*/}
                                        </div>
                                    </Col>
                                    <Col lg="3">
                                        <div className="mb-3">
                                            <Label for="basicpill-firstname-input1">{"Durée d'examen"}</Label>
                                            <Select
                                                options={dure}
                                                isSearchable={true}
                                                onChange={e => getDure(e, index)}
                                                value={element.dure}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg="3">
                                        <div className="mb-3">
                                            <Label for="basicpill-firstname-input1">{"Enseignant d'enseignement"}</Label>
                                            <Select
                                                options={enseignant}
                                                isSearchable={true}
                                                onChange={e => getEnseignant(e, index)}
                                                value={element.responsable_id}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg="3">
                                        <div className="mb-3">
                                            <Label for="basicpill-firstname-input1">Nombre de groupe de
                                                spécialité</Label>
                                            <Input
                                                lg="3"
                                                className="form-control"
                                                placeholder="nbr heure"
                                                type="text"
                                                disabled={true}
                                                value={element.nb_group}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <div style={{display: "flex", justifyContent: "center"}}>
                                    {reste > 0 || reste === "" ? (
                                        <i
                                            style={{
                                                color: "#a0ceb8",
                                                cursor: "pointer",
                                                paddingTop: "5px",
                                            }}
                                            className="fas fa-plus font-size-18"
                                            onClick={() => addLigne(index)}

                                        />
                                    ) : null}

                                    {array.length > 1 ? (
                                        <i
                                            style={{color: "#cc0000"}}
                                            className="mdi mdi-delete font-size-18"
                                            id="deletetooltip"
                                            onClick={() => toggleLigne(index)}
                                        />
                                    ) : null}
                                </div>
                            </Fragment>
                        ))}


                        <div
                            className="hvr-push"
                            style={{
                                display: "flex",
                                justifyContent: "space-around",
                                marginBottom: "15px",
                            }}
                        >
                            <div>
                                <Button
                                    color="info"
                                    className="btn-rounded "
                                    onClick={save}

                                >
                                    <i
                                        style={{color: "white"}}
                                        className="far fa-thumbs-up"
                                    ></i>
                                    Confirmer
                                </Button>
                            </div>
                            <div>
                                <Button
                                    onClick={toggle}
                                    color="danger"
                                    className="btn-rounded "
                                >
                                    <i
                                        style={{color: "white"}}
                                        className="far fa-thumbs-down"
                                    ></i>
                                    Annuler
                                </Button>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
            <Modal size="xl" isOpen={modalDetail} toggle={toggleDetail} centered={true}>
                <ModalHeader
                    style={{width: "100% !important", textAlign: "center !important"}}
                    toggle={toggleDetail}
                    tag="h4"
                >
                    {"Détail d'un calendrier d'examen de date : "+dateDetail}
                </ModalHeader>
                <ModalBody>
                    <div
                        style={{
                            fontFamily: "Montserrat, sans-serif",
                            FontSize: "14px",
                            FontWeight: "700",
                            LineHeight: "18.375px",
                        }}
                    >

                        <table className="table table-bordered text-center">
                            <tbody>
                            {arrayDetail.map((item, index) => (
                                <React.Fragment key={index}>
                                    <tr>
                                        <td colSpan={4} style={{padding: '0'}}>
                                            <span>Spécialité : {item.specialite}</span><br/>
                                            <span>Niveau : {item.niveau}</span><br/>
                                            <span>Matière : {item.matiere}</span><br/>
                                            <span>Durée : {item.duree}</span>
                                            <div style={{padding: "0% 0%"}}>
                                                <button
                                                    onClick={() =>
                                                        toggleDelete(item.specialite_id,item.matiere_id,item.niveau_id,item.date)
                                                    }
                                                    type="button"
                                                    className="btn btn-danger btn-link btn-just-icon btn-sm text-white"
                                                    title="Ajouter Séance"
                                                >
                                                    <i className="fas fa-trash"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{padding: '0'}}>Groupe</td>
                                        <td style={{padding: '0'}}>Capacité du groupe</td>
                                        <td style={{padding: '0'}}>Salle</td>
                                        <td style={{padding: '0'}}>Capacité du salle</td>
                                    </tr>
                                    {item.array_groupes_salles && item.array_groupes_salles.map((subItem, subIndex) => (
                                        <tr key={`${index}-${subIndex}`}>
                                            <td style={{padding: '0'}}>
                                                {subItem.groupe}
                                            </td>
                                            <td style={{padding: '0'}}>
                                                {subItem.capacite_groupe}
                                            </td>
                                            <td style={{ padding: '0' }}>
                                                 {subItem.salle}
                                            </td>
                                            <td style={{ padding: '0' }}>
                                                 {subItem.capacite_salle}
                                            </td>
                                        </tr>
                                    ))}
                                </React.Fragment>
                            ))}
                            </tbody>
                        </table>


                        <div
                            className="hvr-push"
                            style={{
                                display: "flex",
                                justifyContent: "space-around",
                                marginBottom: "15px",
                            }}
                        >

                            <div>
                                <Button
                                    onClick={toggleDetail}
                                    color="info"
                                    className="btn-rounded "
                                >
                                    <i
                                        style={{color: "white"}}
                                        className="far fa-thumbs-down"
                                    ></i>
                                    Annuler
                                </Button>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
            <Modal isOpen={modalLigne} toggle={toggleLigne} centered={true}>
                <ModalHeader toggle={toggleLigne} tag="h4">
                    {" Suppression d'une ligne"}{" "}
                </ModalHeader>
                <ModalBody>

                    <div
                        style={{
                            fontFamily: "Montserrat, sans-serif",
                            FontSize: "14px",
                            FontWeight: "700",
                            LineHeight: "18.375px",
                        }}
                    >

                        <div style={{display: "flex", justifyContent: "center"}}>

                            <p>Voulez-vous supprimer cette ligne ?</p>
                        </div>
                        <div
                            className="hvr-push"
                            style={{
                                display: "flex",
                                justifyContent: "space-around",
                                marginBottom: "15px",
                            }}
                        >
                            <div>
                                <Button
                                    onClick={toggleDeleteLigne}
                                    color="info"
                                    className="btn-rounded "
                                >
                                    <i
                                        style={{color: "white"}}
                                        className="far fa-thumbs-up"
                                    ></i>
                                    Oui
                                </Button>
                            </div>
                            <div>
                                <Button
                                    onClick={toggleLigne}
                                    color="danger"
                                    className="btn-rounded "
                                >
                                    <i
                                        style={{color: "white"}}
                                        className="far fa-thumbs-down"
                                    ></i>
                                    Non
                                </Button>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
            <Modal isOpen={modalDelete} toggle={toggleDelete} centered={true}>
                <ModalHeader
                    style={{width: "100% !important", textAlign: "center !important"}}
                    toggle={toggleDelete}
                    tag="h4"
                >
                    {" Suppression une seance d'examen"}
                </ModalHeader>
                <ModalBody>
                    <div
                        style={{
                            fontFamily: "Montserrat, sans-serif",
                            FontSize: "14px",
                            FontWeight: "700",
                            LineHeight: "18.375px",
                        }}
                    >
                        <h3 style={{color: "red"}}>
                            {"NB : Cette action est irréversible. Si vous supprimez cette séance d'examen, toutes les surveillances relatives seront supprimées automatiquement !!"}
                        </h3>
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <p>{"Voulez - vous supprimer seance d'examen ?"}</p>
                        </div>
                        <div
                            className="hvr-push"
                            style={{
                                display: "flex",
                                justifyContent: "space-around",
                                marginBottom: "15px",
                            }}
                        >
                            <div>
                                <Button
                                    color="info"
                                    className="btn-rounded "
                                    onClick={toggleDeleteSeance}
                                >
                                    <i
                                        style={{color: "white"}}
                                        className="far fa-thumbs-up"
                                    ></i>
                                    Oui
                                </Button>
                            </div>
                            <div>
                                <Button
                                    onClick={toggleDelete}
                                    color="danger"
                                    className="btn-rounded "
                                >
                                    <i
                                        style={{color: "white"}}
                                        className="far fa-thumbs-down"
                                    ></i>
                                    Non
                                </Button>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
            <Modal isOpen={modalErreur} toggle={toggleErreur} centered={true}>
                <div
                    className="modal-header"
                    style={{
                        width: "100% !important",
                        justifyContent: "center !important",
                        margin: "0 auto",
                        fontSize: "17px"
                    }}
                    toggle={toggleErreur}
                >
                    {title}
                </div>
                <ModalBody>
                    <div
                        style={{
                            fontFamily: "Montserrat, sans-serif",
                            FontSize: "14px",
                            FontWeight: "700",
                            LineHeight: "18.375px"
                        }}
                    >
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <p>{msg}</p>
                        </div>
                        <div
                            className="hvr-push"
                            style={{
                                display: "flex",
                                justifyContent: "space-around",
                                marginBottom: "15px",
                                marginTop:"17px"
                            }}
                        >

                            <div>
                                <Button
                                    onClick={toggleErreur}
                                    color="danger"
                                    className="btn-rounded "
                                >
                                    <i
                                        style={{ color: "white" }}
                                        className="far fa-thumbs-down"
                                    ></i>
                                    Fermer
                                </Button>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

            <Modal isOpen={modalConfirmerGroupeMemeSeance} toggle={toggleConfirmerGroupeMemeSeance} centered={true}>
                <ModalHeader
                    style={{width: "100% !important", textAlign: "center !important"}}
                    toggle={toggleConfirmerGroupeMemeSeance}
                    tag="h4"
                >
                    {/*{" Confirmation d'affecter deux groupe d'examen dans même salle "}*/}
                    {msg}
                </ModalHeader>
                <ModalBody>
                    <div
                        style={{
                            fontFamily: "Montserrat, sans-serif",
                            FontSize: "14px",
                            FontWeight: "700",
                            LineHeight: "18.375px",
                        }}
                    >

                        <div style={{display: "flex", justifyContent: "center"}}>
                            <p>{"Voulez - vous confirmer l'affectation deux groupe d'examen dans même salle ?"}</p>
                        </div>
                        <div
                            className="hvr-push"
                            style={{
                                display: "flex",
                                justifyContent: "space-around",
                                marginBottom: "15px",
                            }}
                        >
                            <div>
                                <Button color="info" className="btn-rounded " onClick={toggleSeance}>
                                    <i style={{color: "white"}} className="far fa-thumbs-up"></i>
                                    Oui
                                </Button>
                            </div>
                            <div>
                                <Button onClick={toggleConfirmerGroupeMemeSeance} color="danger" className="btn-rounded ">
                                    <i style={{color: "white"}} className="far fa-thumbs-down"></i>
                                    Non
                                </Button>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
            <Modal isOpen={modalConfirmerGroupeMemeSeanceSave} toggle={toggleConfirmerGroupeMemeSeanceSave} centered={true}>
                <ModalHeader
                    style={{width: "100% !important", textAlign: "center !important"}}
                    toggle={toggleConfirmerGroupeMemeSeanceSave}
                    tag="h4"
                >
                    {/*{" Confirmation d'affecter deux groupe d'examen dans même salle "}*/}
                    {msg}
                </ModalHeader>
                <ModalBody>
                    <div
                        style={{
                            fontFamily: "Montserrat, sans-serif",
                            FontSize: "14px",
                            FontWeight: "700",
                            LineHeight: "18.375px",
                        }}
                    >

                        <div style={{display: "flex", justifyContent: "center"}}>
                            <p>{"Voulez - vous confirmer l'affectation deux groupe d'examen dans même salle ?"}</p>
                        </div>
                        <div
                            className="hvr-push"
                            style={{
                                display: "flex",
                                justifyContent: "space-around",
                                marginBottom: "15px",
                            }}
                        >
                            <div>
                                <Button color="info" className="btn-rounded " onClick={toggleSeanceSave}>
                                    <i style={{color: "white"}} className="far fa-thumbs-up"></i>
                                    Oui
                                </Button>
                            </div>
                            <div>
                                <Button onClick={toggleConfirmerGroupeMemeSeanceSave} color="danger" className="btn-rounded ">
                                    <i style={{color: "white"}} className="far fa-thumbs-down"></i>
                                    Non
                                </Button>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
            <ToastContainer
                transition={Slide}
                enableMultiContainer
                containerId={"A"}
                position={toast.POSITION.TOP_RIGHT}
                autoClose={2500}
            />
        </React.Fragment>
    )
}

export default AddSeanceExamen
AddSeanceExamen.propTypes = {
    history: PropTypes.object,
    back: PropTypes.object,
    setSection: PropTypes.object,
    semestre: PropTypes.object,
}
