import React, { useState, useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import Select from "react-select"
import countryList from "react-select-country-list"
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import API from "../../api"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"

const EditEtudiant = props => {
  const [loading, setLoading] = useState(true)
  const [id, setId] = useState("")
  const [sexe, setSexe] = useState(1);
  const [date, setDate] = useState("")
  const options = useMemo(() => countryList().getData(), [])
  const [etatCivil, setEtatCivil] = useState(1)
  const [gouvernorat, setGouvernorat] = useState([])
  const [selectGouvernorat, setSelectGouvernorat] = useState("");
  const [email, setEmail] = useState("")
  const [anneeBac, setAnneeBac] = useState("")
  const [section, setSection] = useState([])
  const [selectSection, setSelectSection] = useState("");
  const [mention, setMention] = useState([])
  const [selectMention, setSelectMention] = useState("");
  const [session, setSession] = useState([])
  const [selectSession, setSelectSession] = useState("");
  const [cycle, setCycle] = useState([])
  const [selectCycle, setSelectCycle] = useState("")
  const [niveau, setNiveau] = useState([])
  const [selectNiveau, setSelectNiveau] = useState("")
  const [specialite, setSpecialite] = useState([])
  const [selectSpecialite, setSelectSpecialite] = useState("")
  const [groupe, setGroupe] = useState([])
  const [selectGroupe, setSelectGroupe] = useState("")
  const [file, setFile] = useState(null)
  const [cin, setCin] = useState("");
  const [numero_inscription, setNumero_inscription] = useState("");
  const [typeInscription, setTypeInscription] = useState([]);
  const [selectTypeInscription, setSelectTypeInscription] = useState("");
  const [derogataire, setDerogataire] = useState(2);
  const [prenom, setPrenom] = useState("")
  const [nom, setNom] = useState("")
  const [rue, setRue] = useState("")
  const [codePostal, setCodePostal] = useState("")
  const [lieuNaissance, setLieuNaissance] = useState("")
  const [selectNationality, setSelectNationality] = useState("")
  const [tel, setTel] = useState("")
  var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
  var annee_id = userAuthScolarite.user.annee_id
  useEffect(async () => {
    API.get("type/inscription/select").then(restype => {
      setTypeInscription(restype.data.TypeInscription)
    })
    API.get("cycle/select").then(resC => {
      setCycle(resC.data.Cycle)
    })
    API.get("gouvernorat/select").then(res => {
      setGouvernorat(res.data.Gouvernorat)
    })
    API.get("mention/select").then(res => {
      setMention(res.data.Mention)
    })
    API.get("section/select").then(res => {
      setSection(res.data.Section)
    })
    API.get("session/select").then(res => {
      setSession(res.data.Session)
    })
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    const res = await API.post("etudiant/get_by_id", {
      id: id,annee_id
    }).then(res => {
      setId(res.data.etudiant.id)
      setNom(res.data.etudiant.nom)
      setPrenom(res.data.etudiant.prenom)
      setCin(res.data.etudiant.cin)
      setNumero_inscription(res.data.etudiant.numero_inscription)
      setSexe(res.data.etudiant.sexe_id)
      // setDate(res.data.etudiant.dateNaissance)
      setSelectNationality(res.data.etudiant.nationalite)
      setLieuNaissance(res.data.etudiant.lieuNaissance)
      setTel(res.data.etudiant.telephone)
      setEmail(res.data.etudiant.email)
      setRue(res.data.etudiant.rue)
      setCodePostal(res.data.etudiant.codePostal)
      setSelectGouvernorat(res.data.gouvernorat)
      setAnneeBac(res.data.etudiant.anneBac)
      setSelectSection(res.data.etudiant.section)
      setSelectMention(res.data.etudiant.mention)
      setSelectSession(res.data.etudiant.session)
      setSelectCycle(res.data.etudiant.cycle)
      setSelectNiveau(res.data.etudiant.niveau)
      setSelectSpecialite(res.data.etudiant.specialite)
      setSelectGroupe(res.data.etudiant.group_td)
      setDerogataire(res.data.etudiant.derogataire)
      setSelectTypeInscription(res.data.etudiant.type_inscription)
      setLoading(true)
    })
  }, [])

  const getNiveau = async event => {
    var cycle_id = event.value
    setSelectCycle(event)
    setSelectNiveau("")
    setSelectSpecialite("")
    setSelectGroupe("")
    if (cycle_id == 1) {
      const res = await API.get("niveau/select").then(res => {
        setNiveau(res.data.Niveau)
      })
    } else {
      const res = await API.get("niveau/select_mastere").then(res => {
        setNiveau(res.data.Niveau)
      })
    }
  }

  const getSpec = async event => {
    var niveau_id = event.value
    setSelectSpecialite("")
    setSelectGroupe("")
    setSelectNiveau(event)
    const res = await API.post("specialite/get_by_niveau", {
      niveau_id: niveau_id,
      cycle_id: selectCycle.value,
    }).then(res => {
      setSpecialite(res.data.specialite)
    })
  }

  const getGroupe = async event => {
    var specialite_id = event.value
    var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
    var annee_id = userAuthScolarite.user.annee_id
    setSelectSpecialite(event)
    setSelectGroupe("")
    const res = await API.post("group/getbysp", {
      specialite_id: specialite_id,
      cycle_id: selectCycle.value,
      niveau_id: selectNiveau.value,
      annee_id:annee_id
    }).then(res => {
      setGroupe(res.data.groups)
    })
  }

  const addFile = e => {
    var file = e.target.files[0]
    setFile(file)
  }

  const editEtudiant = async () => {
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    if (date != "") {
      let dateComm = date
      let month = "" + (dateComm.getMonth() + 1)
      let day = "" + dateComm.getDate()
      let year = dateComm.getFullYear()
      if (month.length < 2) month = "0" + month
      if (day.length < 2) day = "0" + day
      var convertDate = [year, month, day].join("-")
    } else {
      var convertDate = ""
    }
    alert(file)
    var pathFile = ""
    if (file !== "" && file != null) {
      pathFile = file.name
    }
    alert(pathFile)
    if (selectCycle == "") {
      toast.error("⛔ Veuillez choisir un cycle", {
        containerId: "A",
      })
    } else if (selectSpecialite == "") {
      toast.error("⛔ Veuillez choisir une spécialité", {
        containerId: "A",
      })
    } else if (selectNiveau == "") {
      toast.error("⛔ Veuillez choisir un niveau", {
        containerId: "A",
      })
    } else if (selectGroupe == "") {
      toast.error("⛔ Veuillez choisir un groupe", {
        containerId: "A",
      })

    } else if (selectTypeInscription == "") {
      toast.error("⛔ Veuillez choisir un type d'inscription", {
        containerId: "A",
      })

    }  else if (nom != "" && prenom != "" && cin != "" ) {
      var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
      var annee_id = userAuthScolarite.user.annee_id
      const res = await API.post("etudiant/update", {
        id: id,
        nom:nom,
        prenom:prenom,
        cin:cin,
        numero_inscription:numero_inscription,
        sexe:sexe,
        dateNaissance:convertDate,
        lieuNaissance:lieuNaissance,
        nationalite:selectNationality.label,
        email:email,
        telephone:tel,
        etatCivil:etatCivil,
        rue:rue,
        codePostal:codePostal,
        fichier:pathFile,
        anneBac:anneeBac,
        derogataire:derogataire,
        type_inscription_id:selectTypeInscription.value,
        gouvernorat_id:selectGouvernorat.value,
        mention_id:selectMention.value,
        section_id:selectSection.value,
        session_id:selectSession.value,
        cycle_id:selectCycle.value,
        niveau_id:selectNiveau.value,
        specialite_id:selectSpecialite.value,
        group_td_id:selectGroupe.value,
        annee_id:annee_id
      })
        .then(res => {
          props.history.push("/Etudiant")
          if (pathFile != "") {
            const formData = new FormData()
            formData.append("document", file)
            formData.append("annee", "2022")
            formData.append("entite", "etudiant")
            formData.append("type", "photo")
            formData.append("id", numero)
            const resA = API.post("file/import", formData).then(resA => {})
          }
        })
        // .catch(() => {
        //   toast.error("⛔  Problème lors de l'insertion !", {
        //     containerId: "A",
        //   })
        // })
    } else {
      toast.error("⛔  Remplir les champs obligatoires", {
        containerId: "A",
      })
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Card>
              <CardBody>
                {loading ? (
                    <Row>
                      <AvForm>
                        <Form>
                          <CardTitle style={{ color: "#556ee6" }} className="h4 mb-4">
                            Informations générales :
                          </CardTitle>
                          <Row>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Nom :</Label>
                                <AvField
                                    value={nom}
                                    name="nom"
                                    placeholder="Entrer nom"
                                    type="text"
                                    errorMessage="* nom obligatoire"
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    onChange={e => setNom(e.target.value)}
                                />
                              </div>
                            </Col>
                            <Col md="6">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Prénom :</Label>
                                <AvField
                                    value={prenom}
                                    name="prenom"
                                    placeholder="Entrer prénom"
                                    type="text"
                                    errorMessage="* prenom obligatoire"
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    onChange={e => setPrenom(e.target.value)}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">CIN :</Label>
                                <AvField
                                    value={cin}
                                    name="numero"
                                    placeholder="Entrer numéro"
                                    type="text"
                                    errorMessage="Min 8 caractère"
                                    className="form-control"
                                    validate={{ required: { value: true }, min: { value: 8 } }}
                                    onChange={e => setCin(e.target.value)}
                                />
                              </div>
                            </Col>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">{"Numéro d'inscription :"}</Label>
                                <AvField
                                    value={numero_inscription}
                                    name="numero_inscription"
                                    placeholder="Entrer Numéro d'inscription "
                                    type="text"
                                    className="form-control"
                                    onChange={e => setNumero_inscription(e.target.value)}
                                />
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col md="6">
                              <div className="mb-3">
                                <Label className="d-block mb-3">Sexe :</Label>
                                <div className="form-check form-check-inline">
                                  <Input
                                      type="radio"
                                      id="customRadioInline1"
                                      name="customRadioInline1"
                                      className="form-check-input"
                                      checked={sexe == 1}
                                      onChange={e => setSexe(1)}
                                  />
                                  <Label
                                      className="form-check-label"
                                      htmlFor="customRadioInline1"
                                  >
                                    Masculin
                                  </Label>
                                </div>
                                &nbsp;
                                <div className="form-check form-check-inline">
                                  <Input
                                      type="radio"
                                      id="customRadioInline2"
                                      name="customRadioInline1"
                                      className="form-check-input"
                                      checked={sexe == 2}
                                      onChange={e => setSexe(2)}
                                  />
                                  <Label
                                      className="form-check-label"
                                      htmlFor="customRadioInline2"
                                  >
                                    Féminin
                                  </Label>
                                </div>
                              </div>
                            </Col>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">
                                  Date de Naissance :{" "}
                                </Label>
                                <Col md={12} className="pr-0">
                                  <DatePicker
                                      className="form-control ddate"
                                      selected={date}
                                      onChange={e => setDate(e)}
                                      dateFormat="dd/MM/yyyy"
                                  />
                                </Col>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Nationalité :</Label>
                                <Select
                                    value={selectNationality}
                                    options={options}
                                    isSearchable={true}
                                    onChange={e => setSelectNationality(e)}
                                />
                              </div>
                            </Col>              <Col lg="6">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Lieu de Naissance :
                              </Label>
                              <Input
                                  value={lieuNaissance}
                                  lg="3"
                                  className="form-control"
                                  placeholder="Entrer lieu de naissance"
                                  type="text"
                                  onChange={e => setLieuNaissance(e.target.value)}
                              />
                            </div>
                          </Col>
                          </Row>

                          <Row>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Téléphone : </Label>
                                <AvField
                                    value={tel}
                                    name="tel"
                                    placeholder="Entrer téléphone"
                                    type="text"
                                    className="form-control"
                                    // validate={{  min: { value: 8 } }}
                                    // validate={{ required: { value: true }, min: { value: 8 } }}
                                    onChange={e => setTel(e.target.value)}
                                />
                              </div>
                            </Col>
                            <Col lg="6">
                              <div className="mb-3">
                                <AvField
                                    value={email}
                                    name="email"
                                    label="E-Mail"
                                    placeholder="Entrer un email valide"
                                    type="email"
                                    onChange={e => setEmail(e.target.value)}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="6">
                              <div className="mb-3">
                                <Label className="d-block mb-3">Etat Civil :</Label>
                                <div className="form-check form-check-inline">
                                  <Input
                                      type="radio"
                                      id="customRadioInline5"
                                      name="customRadioInline5"
                                      className="form-check-input"
                                      checked={etatCivil === 1}
                                      onChange={e => setEtatCivil(1)}
                                  />
                                  <Label
                                      className="form-check-label"
                                      htmlFor="customRadioInline5"
                                  >
                                    Célibataire
                                  </Label>
                                </div>
                                &nbsp;
                                <div className="form-check form-check-inline">
                                  <Input
                                      type="radio"
                                      id="customRadioInline6"
                                      name="customRadioInline5"
                                      className="form-check-input"
                                      checked={etatCivil === 2}
                                      onChange={e => setEtatCivil(2)}
                                  />
                                  <Label
                                      className="form-check-label"
                                      htmlFor="customRadioInline6"
                                  >
                                    Marié(e)
                                  </Label>
                                </div>
                                &nbsp;
                                <div className="form-check form-check-inline">
                                  <Input
                                      type="radio"
                                      id="customRadioInline7"
                                      name="customRadioInline5"
                                      className="form-check-input"
                                      checked={etatCivil === 3}
                                      onChange={e => setEtatCivil(3)}
                                  />
                                  <Label
                                      className="form-check-label"
                                      htmlFor="customRadioInline7"
                                  >
                                    Divorcé(e)
                                  </Label>
                                </div>
                              </div>
                            </Col>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Photo :</Label>
                                <Input
                                    onChange={e => addFile(e)}
                                    className="form-control"
                                    type="file"
                                    id="formFile"
                                />
                              </div>
                            </Col>
                          </Row>
                          <CardTitle style={{ color: "#556ee6" }} className="h4 mb-4 mt-4">
                            Adresse :
                          </CardTitle>
                          <Row>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Rue : </Label>
                                <Input
                                    value={rue}
                                    lg="3"
                                    className="form-control"
                                    placeholder="Entrer rue"
                                    type="text"
                                    onChange={e => setRue(e.target.value)}
                                />
                              </div>
                            </Col>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Code postal :</Label>
                                <Input
                                    value={codePostal}
                                    lg="3"
                                    className="form-control"
                                    placeholder="Entrer code postal"
                                    type="number"
                                    onChange={e => setCodePostal(e.target.value)}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>

                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Gouvernorat :</Label>
                                <Select
                                    value={selectGouvernorat}
                                    options={gouvernorat}
                                    isSearchable={true}
                                    onChange={e => setSelectGouvernorat(e)}
                                />
                              </div>
                            </Col>
                          </Row>

                          <CardTitle style={{ color: "#556ee6" }} className="h4 mb-4 mt-4">
                            Baccalauréat :
                          </CardTitle>
                          <Row>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">
                                  Année du bac :{" "}
                                </Label>
                                <Input
                                    value={anneeBac}
                                    lg="3"
                                    className="form-control"
                                    placeholder="Entrer année du bac"
                                    type="text"
                                    onChange={e => setAnneeBac(e.target.value)}
                                />
                              </div>
                            </Col>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Section :</Label>
                                <Select
                                    value={selectSection}
                                    options={section}
                                    isSearchable={true}
                                    onChange={e => setSelectSection(e)}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>

                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Mention : </Label>
                                <Select
                                    value={selectMention}
                                    options={mention}
                                    isSearchable={true}
                                    onChange={e => setSelectMention(e)}
                                />
                              </div>
                            </Col>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Session :</Label>
                                <Select
                                    value={selectSession}
                                    options={session}
                                    isSearchable={true}
                                    onChange={e => setSelectSession(e)}
                                />
                              </div>
                            </Col>
                          </Row>

                          <CardTitle style={{ color: "#556ee6" }} className="h4 mb-4 mt-4">
                            Diplôme :
                          </CardTitle>
                          <Row>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Cycle : </Label>
                                <Select
                                    options={cycle}
                                    isSearchable={true}
                                    value={selectCycle}
                                    onChange={e => getNiveau(e)}
                                />
                              </div>
                            </Col>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Niveau : </Label>
                                <Select
                                    options={niveau}
                                    isSearchable={true}
                                    value={selectNiveau}
                                    onChange={e => getSpec(e)}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Spécialité :</Label>
                                <Select
                                    options={specialite}
                                    isSearchable={true}
                                    value={selectSpecialite}
                                    onChange={e => getGroupe(e)}
                                />
                              </div>
                            </Col>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Groupe : </Label>
                                <Select
                                    options={groupe}
                                    isSearchable={true}
                                    value={selectGroupe}
                                    isMulti={true}
                                    onChange={e => setSelectGroupe(e)}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>

                            <Col md="6">
                              <div className="mb-3">
                                <Label className="d-block mb-3">Etat Etudiant :</Label>
                                <div className="form-check form-check-inline">
                                  <Input
                                      type="radio"
                                      id="customRadioInline9"
                                      name="customRadioInline9"
                                      className="form-check-input"
                                      checked={derogataire === 1}
                                      onChange={e => setDerogataire(1)}
                                  />
                                  <Label
                                      className="form-check-label"
                                      htmlFor="customRadioInline9"
                                  >
                                    Dérogataire
                                  </Label>
                                </div>
                                &nbsp;
                                <div className="form-check form-check-inline">
                                  <Input
                                      type="radio"
                                      id="customRadioInline10"
                                      name="customRadioInline9"
                                      className="form-check-input"
                                      checked={derogataire === 2}
                                      onChange={e => setDerogataire(2)}
                                  />
                                  <Label
                                      className="form-check-label"
                                      htmlFor="customRadioInline10"
                                  >
                                    Non Dérogataire
                                  </Label>
                                </div>

                              </div>
                            </Col>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">{"Type d'inscription : "}</Label>
                                <Select
                                    options={typeInscription}
                                    isSearchable={true}
                                    value={selectTypeInscription}
                                    onChange={e => setSelectTypeInscription(e)}
                                />
                              </div>
                            </Col>
                          </Row>
                        </Form>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                          {" "}
                          <Col lg="6">
                            <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                                className="text-center mt-4"
                            >
                              <Button
                                  type="button"
                                  color="warning"
                                  className="btn btn-warning  mb-2 me-2"
                                  onClick={props.back}
                              >
                                Annuler
                              </Button>
                            </div>
                          </Col>
                          <Col lg="6">
                            <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                }}
                                className="text-center mt-4"
                            >
                              <button
                                  onClick={editEtudiant}
                                  type="submit"
                                  className="btn btn-primary "
                              >
                                CONFIRMER
                              </button>
                            </div>
                          </Col>
                        </div>
                      </AvForm>
                    </Row>
                ) : (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {" "}
                      <Spinner type="grow" className="ms-6" color="primary" />
                    </div>
                    <h4
                      style={{ textAlign: "center", marginTop: "2%" }}
                      className="ms-6"
                    >
                      {" "}
                      {"Chargement"} ...{" "}
                    </h4>
                  </div>
                )}
              </CardBody>
            </Card>
          </Row>
          <ToastContainer
            transition={Slide}
            enableMultiContainer
            containerId={"A"}
            position={toast.POSITION.TOP_RIGHT}
            autoClose={2500}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EditEtudiant
EditEtudiant.propTypes = {
  history: PropTypes.object,
  back:PropTypes.object
}
