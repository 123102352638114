import React, { Fragment, useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  CardTitle,
  Col,
  Form,
  Label,
  Row,
  Container,
  Card,
  CardBody,
  Spinner,
} from "reactstrap"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import API from "../../api"
import { withTranslation } from "react-i18next"
import Select from "react-select"

const FicheVoeux = props => {
  const [selectJour, setSelectJour] = useState("")
  const [selectSemestre, setSelectSemestre] = useState("")
  const [arrayFiche, setArrayFiche] = useState([
    { ordre: "", niveau_id: "", specialite_id: "", matiere_id: "", type: "" },
  ])
  const [arrayFiche1, setArrayFiche1] = useState([
    {
      ordre1: "",
      niveau_id1: "",
      specialite_id1: "",
      matiere_id1: "",
      type1: "",
    },
  ])
  const [selectJour1, setSelectJour1] = useState("")
  const [selectSemestre1, setSelectSemestre1] = useState("")
  const [arrayFiche2, setArrayFiche2] = useState([
    { ordre: "", niveau_id: "", specialite_id: "", matiere_id: "", type: "" },
  ])
  const [arrayFiche3, setArrayFiche3] = useState([
    {
      ordre1: "",
      niveau_id1: "",
      specialite_id1: "",
      matiere_id1: "",
      type1: "",
    },
  ])
  const [etat, setEtat] = useState("")
  const [etat1, setEtat1] = useState("")
  const [loading, setLoading] = useState(false)
  const [session, setSession] = useState([
    {value:1,label:"Matin"},
    {value:2,label:"Après-Midi"},
  ])
  const [jours, setJours] = useState([
    { value: 1, label: "Lundi" },
    { value: 2, label: "Mardi" },
    { value: 3, label: "Mercredi" },
    { value: 4, label: "Jeudi" },
    { value: 5, label: "Vendredi" },
    { value: 6, label: "Samedi" },
  ])
  const [arrayJour, setArrayJour] = useState([]);
  const [arrayJour1, setArrayJour1] = useState([]);
  const [annee, setAnnee] = useState("");
  if (localStorage.getItem("userAuthScolarite")) {
    var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
    var user_id = userAuthScolarite.user.id
    var annee_id = userAuthScolarite.user.annee_id
  }

  useEffect(async () => {
    const fetchAnnee = async () => {
      const resD = await API.post("annee/get_by_id", { id: annee_id });
      setAnnee(resD.data.Annee.annee_scolaire);
    };
    fetchAnnee()
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    const res = await API.post("fiche/get_by_id_enseignant", { id ,annee_id}).then(
      res => {
        setEtat(res.data.FicheVoeau.semestre1)
        setSelectSemestre(res.data.FicheVoeau.semestre1.semestre_id)
        setArrayJour(res.data.FicheVoeau.semestre1.jour_id)
        setArrayFiche(res.data.FicheVoeau.semestre1.ligne)
        setArrayFiche1(res.data.FicheVoeau.semestre1.ligne1)
        setEtat1(res.data.FicheVoeau.semestre2)
        setSelectSemestre1(res.data.FicheVoeau.semestre2.semestre_id)
        setArrayJour1(res.data.FicheVoeau.semestre2.jour_id)
        setArrayFiche2(res.data.FicheVoeau.semestre2.ligne)
        setArrayFiche3(res.data.FicheVoeau.semestre2.ligne1)
        setLoading(true)
      }
    )
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              {loading ? (
                <Row>
                  <Form>
                    {etat.length != 0 ? (
                      <Fragment>
                        <CardTitle style={{ color: "#f4912d" }}>
                          Semestre 1 :
                        </CardTitle>
                        <Row>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                {props.t("Semestre")} :
                              </Label>
                              <Select
                                isSearchable={true}
                                value={selectSemestre}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Label for="basicpill-firstname-input1">
                            {props.t("Choix des jours")} :
                          </Label>
                          <table className="table table-bordered mt-4">
                            <thead>
                            <tr>
                              <th style={{textAlign: "center"}} scope="col">
                                Jours
                              </th>
                              <th style={{textAlign: "center"}} scope="col">
                                Session
                              </th>

                            </tr>
                            </thead>
                            {arrayJour.map((el, index) => (
                                <tbody key={index}>
                                <tr>
                                  <td style={{textAlign: "center"}} scope="col">
                                    <Select
                                        options={jours}
                                        isSearchable={true}
                                        value={el.jour_id}

                                    />
                                  </td>
                                  <td style={{textAlign: "center"}} scope="col">
                                    <Select
                                        value={el.session}
                                        options={session}
                                        isSearchable={true}

                                    />
                                  </td>

                                </tr>
                                </tbody>
                            ))}
                          </table>
                        </Row>
                        <div className="mt-4 mb-4">
                          <h5>
                            Veuillez indiquer vos choix concernant les matières
                            que vous désiriez assurer au cours de l’année
                            universitaire {annee}, et de préciser la forme
                            d’enseignement voulue (Cours, TD, TP, Autre).
                          </h5>
                        </div>
                        <CardTitle
                          style={{ color: "#556ee6" }}
                          className="h4 mb-2 mt-2"
                        >
                          Choix des matiéres pour licence:
                        </CardTitle>
                        <table className="table table-bordered mt-4">
                          <thead>
                            <tr>
                              <th style={{ textAlign: "center" }} scope="col">
                                Ordre 
                              </th>
                              <th style={{ textAlign: "center" }} scope="col">
                                Niveau
                              </th>
                              <th style={{ textAlign: "center" }} scope="col">
                                Spécialité
                              </th>
                              <th style={{ textAlign: "center" }} scope="col">
                                Matiere proposé
                              </th>
                              <th style={{ textAlign: "center" }} scope="col">
                                Type seance
                              </th>
                            </tr>
                          </thead>
                          {arrayFiche.map((el, index) => (
                            <tbody key={index}>
                              <tr>
                                <td className="col-md-2">
                                  {" "}
                                  <Select
                                    isSearchable={true}
                                    value={el.ordre}
                                  />
                                </td>
                                <td className="col-md-2">
                                  <Select
                                    isSearchable={true}
                                    value={el.niveau_id}
                                  />
                                </td>
                                <td className="col-md-3">
                                  <Select
                                    isSearchable={true}
                                    value={el.specialite_id}
                                  />
                                </td>
                                <td className="col-md-3">
                                  <Select
                                    isSearchable={true}
                                    value={el.matiere_id}
                                  />
                                </td>
                                <td className="col-md-2">
                                  <Select isSearchable={true} value={el.type} />
                                </td>
                              </tr>
                            </tbody>
                          ))}
                        </table>
                        <CardTitle
                          style={{ color: "#556ee6" }}
                          className="h4 mb-2 mt-2"
                        >
                          Choix des matiéres pour mastère:
                        </CardTitle>
                        <table className="table table-bordered mt-4">
                          <thead>
                            <tr>
                              <th style={{ textAlign: "center" }} scope="col">
                                Ordre 
                              </th>
                              <th style={{ textAlign: "center" }} scope="col">
                                Niveau
                              </th>
                              <th style={{ textAlign: "center" }} scope="col">
                                Spécialité
                              </th>
                              <th style={{ textAlign: "center" }} scope="col">
                                Matiere proposé
                              </th>
                              <th style={{ textAlign: "center" }} scope="col">
                                Type seance
                              </th>
                            </tr>
                          </thead>
                          {arrayFiche1.map((el, index) => (
                            <tbody key={index}>
                              <tr>
                                <td className="col-md-2">
                                  <Select
                                    isSearchable={true}
                                    value={el.ordre1}
                                  />{" "}
                                </td>
                                <td className="col-md-2">
                                  <Select
                                    isSearchable={true}
                                    value={el.niveau_id1}
                                  />
                                </td>
                                <td className="col-md-3">
                                  <Select
                                    isSearchable={true}
                                    value={el.specialite_id1}
                                  />
                                </td>
                                <td className="col-md-3">
                                  <Select
                                    isSearchable={true}
                                    value={el.matiere_id1}
                                  />
                                </td>
                                <td className="col-md-2">
                                  <Select
                                    isSearchable={true}
                                    value={el.type1}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          ))}
                        </table>
                      </Fragment>
                    ) : null}
                    {etat1.length != 0 ? (
                      <Fragment>
                        <CardTitle style={{ color: "#f4912d" }}>
                          Semestre 2 :
                        </CardTitle>
                        <Row>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                {props.t("Semestre")} :
                              </Label>
                              <Select
                                isSearchable={true}
                                value={selectSemestre1}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Label for="basicpill-firstname-input1">
                            {props.t("Choix des jours")} :
                          </Label>
                          <table className="table table-bordered mt-4">
                            <thead>
                            <tr>
                              <th style={{textAlign: "center"}} scope="col">
                                Jours
                              </th>
                              <th style={{textAlign: "center"}} scope="col">
                                Session
                              </th>

                            </tr>
                            </thead>
                            {arrayJour1.map((el, index) => (
                                <tbody key={index}>
                                <tr>
                                  <td style={{textAlign: "center"}} scope="col">
                                    <Select
                                        options={jours}
                                        isSearchable={true}
                                        value={el.jour_id}

                                    />
                                  </td>
                                  <td style={{textAlign: "center"}} scope="col">
                                    <Select
                                        value={el.session}
                                        options={session}
                                        isSearchable={true}

                                    />
                                  </td>

                                </tr>
                                </tbody>
                            ))}
                          </table>
                        </Row>
                        <div className="mt-4 mb-4">
                          <h5>
                            Veuillez indiquer vos choix concernant les matières
                            que vous désiriez assurer au cours de l’année
                            universitaire {annee}, et de préciser la forme
                            d’enseignement voulue (Cours, TD, TP, Autre).
                          </h5>
                        </div>
                        <CardTitle
                          style={{ color: "#556ee6" }}
                          className="h4 mb-2 mt-2"
                        >
                          Choix des matiéres pour licence:
                        </CardTitle>
                        <table className="table table-bordered mt-4">
                          <thead>
                            <tr>
                              <th style={{ textAlign: "center" }} scope="col">
                                Ordre 
                              </th>
                              <th style={{ textAlign: "center" }} scope="col">
                                Niveau
                              </th>
                              <th style={{ textAlign: "center" }} scope="col">
                                Spécialité
                              </th>
                              <th style={{ textAlign: "center" }} scope="col">
                                Matiere proposé
                              </th>
                              <th style={{ textAlign: "center" }} scope="col">
                                Type seance
                              </th>
                            </tr>
                          </thead>
                          {arrayFiche2.map((el, index) => (
                            <tbody key={index}>
                              <tr>
                                <td className="col-md-2">
                                  {" "}
                                  <Select
                                    isSearchable={true}
                                    value={el.ordre}
                                  />
                                </td>
                                <td className="col-md-2">
                                  <Select
                                    isSearchable={true}
                                    value={el.niveau_id}
                                  />
                                </td>
                                <td className="col-md-3">
                                  <Select
                                    isSearchable={true}
                                    value={el.specialite_id}
                                  />
                                </td>
                                <td className="col-md-3">
                                  <Select
                                    isSearchable={true}
                                    value={el.matiere_id}
                                  />
                                </td>
                                <td className="col-md-2">
                                  <Select isSearchable={true} value={el.type} />
                                </td>
                              </tr>
                            </tbody>
                          ))}
                        </table>
                        <CardTitle
                          style={{ color: "#556ee6" }}
                          className="h4 mb-2 mt-2"
                        >
                          Choix des matiéres pour mastère:
                        </CardTitle>
                        <table className="table table-bordered mt-4">
                          <thead>
                            <tr>
                              <th style={{ textAlign: "center" }} scope="col">
                                Ordre 
                              </th>
                              <th style={{ textAlign: "center" }} scope="col">
                                Niveau
                              </th>
                              <th style={{ textAlign: "center" }} scope="col">
                                Spécialité
                              </th>
                              <th style={{ textAlign: "center" }} scope="col">
                                Matiere proposé
                              </th>
                              <th style={{ textAlign: "center" }} scope="col">
                                Type seance
                              </th>
                            </tr>
                          </thead>
                          {arrayFiche3.map((el, index) => (
                            <tbody key={index}>
                              <tr>
                                <td className="col-md-2">
                                  <Select
                                    isSearchable={true}
                                    value={el.ordre1}
                                  />{" "}
                                </td>
                                <td className="col-md-2">
                                  <Select
                                    isSearchable={true}
                                    value={el.niveau_id1}
                                  />
                                </td>
                                <td className="col-md-3">
                                  <Select
                                    isSearchable={true}
                                    value={el.specialite_id1}
                                  />
                                </td>
                                <td className="col-md-3">
                                  <Select
                                    isSearchable={true}
                                    value={el.matiere_id1}
                                  />
                                </td>
                                <td className="col-md-2">
                                  <Select
                                    isSearchable={true}
                                    value={el.type1}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          ))}
                        </table>
                      </Fragment>
                    ) : null}
                  </Form>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {" "}
                    <Col lg="6">
                      <div className="text-center mt-4">
                        <Button
                          type="button"
                          color="warning"
                          className="btn btn-warning  mb-2 me-2"
                          onClick={() => props.history.push("/Enseignant")}
                        >
                          {props.t("Annuler")}
                        </Button>
                      </div>
                    </Col>
                  </div>
                </Row>
              ) : (
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    <Spinner type="grow" className="ms-6" color="primary" />
                  </div>
                  <h4
                    style={{ textAlign: "center", marginTop: "2%" }}
                    className="ms-6"
                  >
                    {" "}
                    {"Chargement"} ...{" "}
                  </h4>
                </div>
              )}
              <ToastContainer
                transition={Slide}
                enableMultiContainer
                containerId={"A"}
                position={toast.POSITION.TOP_RIGHT}
                autoClose={2500}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(FicheVoeux)
FicheVoeux.propTypes = {
  history: PropTypes.object,
  t: PropTypes.any,
}
