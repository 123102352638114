import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import API from "../../api"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit"
import {ToastContainer, toast} from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {Slide} from "react-toastify"
import { Row, Col, Spinner, Modal, ModalBody, Button, ModalHeader,} from "reactstrap"
import {withRouter} from "react-router"
import {withTranslation} from "react-i18next"
import {Link} from "react-router-dom"
import Switch from "react-switch";

const DataTableParametrageExamen = props => {
    const [loading, setLoading] = useState(false)
    const [modalDelete, setModalDelete] = useState(false)
    const [idDelete, setIdDelete] = useState("")
    const [orders, setOrders] = useState([])
    const [edit, setEdit] = useState(0);
    const [delet, setDelet] = useState(0);
    const [cloture, setCloture] = useState(0);
    const [add, setAdd] = useState(0);
    var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
    var annee_id = userAuthScolarite.user.annee_id
    var user_id = userAuthScolarite.user.id;
    const pageOptions = {
        loading: true,
        hidePageListOnlyOnePage: true,
        totalSize: orders.length,
        page: 1,
        nextPageText: props.t("Suivant"),
        prePageText: props.t("Précedent"),
        alwaysShowAllBtns: true,
        sizePerPage: 40,
        sizePerPageList: [
            {
                text: "20",
                value: 20,
            },
            {
                text: "40",
                value: 40,
            },
            {
                text: "50",
                value: 50,
            },
            {
                text: "All",
                value: orders.length,
            },
        ],
    }

    const NoDataIndication = () => (
        <h6 style={{textAlign: "center"}}>Aucun information à afficher</h6>
    )
    const Columns = () => [
        {
            dataField: "semestre",
            text: props.t("Semestre"),
            sort: true,
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
        },
        {
            dataField: "date_debut",
            text: props.t("Date de début"),
            sort: true,
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
        },
        {
            dataField: "date_fin",
            text: props.t("Date fin"),
            sort: true,
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
        },
        {
            dataField: "active",
            isDummyField: true,
            text: "Etat",
            style: { textAlign: "center" },
            headerStyle: (colum, colIndex) => {
                return { textAlign: "center" }
            },
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (colIndex, row) => (
                add === 1 ? (
                    <Switch
                        uncheckedIcon={<Offsymbol/>}
                        checkedIcon={<OnSymbol/>}
                        className="me-1 mb-sm-8 mb-2"
                        onColor="#34c38f"
                        onChange={() => activeEtat(row)}
                        checked={row.active}
                    />
                ) : (
                    <Switch
                        uncheckedIcon={<Offsymbol/>}
                        checkedIcon={<OnSymbol/>}
                        className="me-1 mb-sm-8 mb-2"
                        onColor="#34c38f"

                        checked={row.active}
                        disabled={true}
                    />
                )
            ),
        },
        {
            dataField: "Modification",
            isDummyField: true,
            text: props.t("Modification"),
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => (
                cloture != 1 ? (
                    edit == 1 ? (
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <Link to={"/EditParametrageExamen?id=" + row.id}>
                                <i
                                    style={{color: "cornflowerblue", cursor: "pointer"}}
                                    className="fas fa-edit"
                                ></i>
                            </Link>
                        </div>
                    ) : (
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <i
                                style={{color: "#a7a3a3", cursor: "pointer"}}
                                className="fas fa-edit"
                                title="Ce parametrage déja utilisé"
                            ></i>
                        </div>
                    )
                ) : (
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <i
                            style={{color: "#a7a3a3", cursor: "pointer"}}
                            className="fas fa-edit"
                            title="Année clôturée"
                        ></i>
                    </div>
                )
            ),
        },
        {
            dataField: "Suppression",
            isDummyField: true,
            text: "Suppression",
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => (
                cloture !== 1 ? (
                    delet === 1 ? (
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <i
                                style={{color: "#ED6464", cursor: "pointer"}}
                                onClick={() => toggleDelete(row.id)}
                                className="fas fa-trash-alt"
                            ></i>
                        </div>
                    ) : (
                        <i
                            style={{color: "#696d75", cursor: "pointer"}}
                            className="fas fa-trash-alt"
                            title="Vous n'avez pas le droit"
                        ></i>
                    )
                ) : (
                    <i
                        style={{color: "#696d75", cursor: "pointer"}}
                        className="fas fa-trash-alt"
                        title="Année clôturée"
                    ></i>
                )
            ),
        },
    ]

    const activeEtat = async row => {
        var active = row.active
        var pram = row.id
        if (active == 0) {
            var active = 1
            const res = await API.post("examen_parametrage/changer_etat", {
                id: pram,
                active: active,
                user_id,annee_id
            }).then(res => {
                toast.success(" 🔓✔️ Etat Activé Avec succès", {
                    containerId: "A",
                })
                API.post("examen_parametrage/list",{annee_id}).then(resE => {
                    setOrders(resE.data.params)
                    setLoading(true)
                })
            })
        } else if (active == 1) {
            var active = 0
            const res = await API.post("examen_parametrage/changer_etat", {
                id: pram,
                active: active,
                user_id,annee_id
            }).then(res => {
                toast.success(" 🔒❌ Etat Désactivé Avec succès ", {
                    containerId: "A",
                })
                API.post("examen_parametrage/list",{annee_id}).then(resE => {
                    setOrders(resE.data.params)
                    setLoading(true)
                })
            })
        }
    }
    const Offsymbol = () => {
        return (
            <div
                title="Activé"
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2,
                }}
            >
                {" "}
                A
            </div>
        )
    }

    const OnSymbol = props => {
        return (
            <div
                title="Désactivé"
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2,
                }}
            >
                {" "}
                D
            </div>
        )
    }
    const {SearchBar} = Search

    useEffect(async () => {
        var cloture_annee = JSON.parse(localStorage.getItem("cloture"));
        setCloture(cloture_annee)
        const userAuth = JSON.parse(localStorage.getItem('UserMenu'));
        const typeArticleSubMenu = userAuth.find(user =>
            user.sous_menu.some(subMenu => subMenu.sous_menu === "Paramétrage de date d'examen")
        );
        setAdd(typeArticleSubMenu.sous_menu[0].add)
        setEdit(typeArticleSubMenu.sous_menu[0].edit)
        setDelet(typeArticleSubMenu.sous_menu[0].delete)
        const res = await API.post("examen_parametrage/list", {annee_id}).then(res => {
            setOrders(res.data.params)

        })
        setLoading(true)
    }, [])
    const toggleDelete = id => {
        setModalDelete(!modalDelete)
        setIdDelete(id)
    }

    const toggleDeleteRat = async () => {
        const resD = await API.post("examen_parametrage/supprimer", {
            id: idDelete,
            annee_id,
            user_id
        }).then(resD => {
            setModalDelete(false)
            const res = API.post("examen_parametrage/list", {annee_id}).then(res => {
                setOrders(res.data.params)

            })
            setLoading(true)
        })
    }

    return (
        <React.Fragment>
            <div>
                {loading ? (
                    <Row>
                        <Col xs="12">
                            <ToolkitProvider
                                keyField="id"
                                data={orders}
                                columns={Columns()}
                                search
                                bootstrap4
                            >
                                {toolkitProps => (
                                    <div>
                                        <Col sm="4">
                                            <div className="search-box me-2 mb-2 d-inline-block">
                                                <div className="position-relative">
                                                    <SearchBar {...toolkitProps.searchProps} />
                                                    <i className="bx bx-search-alt search-icon"/>
                                                </div>
                                            </div>
                                        </Col>
                                        <BootstrapTable
                                            wrapperClasses="table-responsive"
                                            noDataIndication={() => <NoDataIndication/>}
                                            striped={false}
                                            bordered={false}
                                            classes={"table align-middle table-nowrap"}
                                            headerWrapperClasses={"table-light"}
                                            hover
                                            pagination={paginationFactory(pageOptions)}
                                            {...toolkitProps.baseProps}
                                        />
                                    </div>
                                )}
                            </ToolkitProvider>
                        </Col>
                    </Row>
                ) : (
                    <div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            {" "}
                            <Spinner type="grow" className="ms-6" color="primary"/>
                        </div>
                        <h4
                            style={{textAlign: "center", marginTop: "2%"}}
                            className="ms-6"
                        >
                            {" "}
                            {props.t("Chargement en cours...")}
                        </h4>
                    </div>
                )}
            </div>

            <Modal isOpen={modalDelete} toggle={toggleDelete} centered={true}>
                <ModalHeader
                    style={{width: "100% !important", textAlign: "center !important"}}
                    toggle={toggleDelete}
                    tag="h4"
                >
                    {"Suppression de paramétrage de DS"}
                </ModalHeader>
                <ModalBody>
                    <div
                        style={{
                            fontFamily: "Montserrat, sans-serif",
                            FontSize: "14px",
                            FontWeight: "700",
                            LineHeight: "18.375px",
                        }}
                    >
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <p>Voulez-vous supprimer cette paramétrage de DS ?</p>
                        </div>
                        <div
                            className="hvr-push"
                            style={{
                                display: "flex",
                                justifyContent: "space-around",
                                marginBottom: "15px",
                            }}
                        >
                            <div>
                                <Button
                                    color="info"
                                    className="btn-rounded "
                                    onClick={toggleDeleteRat}
                                >
                                    <i
                                        style={{color: "white"}}
                                        className="far fa-thumbs-up"
                                    ></i>
                                    Oui
                                </Button>
                            </div>
                            <div>
                                <Button
                                    onClick={toggleDelete}
                                    color="danger"
                                    className="btn-rounded "
                                >
                                    <i
                                        style={{color: "white"}}
                                        className="far fa-thumbs-down"
                                    ></i>
                                    Non
                                </Button>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

            <ToastContainer
                transition={Slide}
                enableMultiContainer
                containerId={"A"}
                position={toast.POSITION.TOP_RIGHT}
                autoClose={2500}
            />
        </React.Fragment>
    )
}

export default withRouter(withTranslation()(DataTableParametrageExamen))
DataTableParametrageExamen.propTypes = {
    orders: PropTypes.array,
    history: PropTypes.object,
    t: PropTypes.any,
}
