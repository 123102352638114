import React, { Fragment, useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  CardTitle,
  Col,
  Form,
  Label,
  Row,
  Container,
  Card,
  CardBody,
  Spinner, Modal, ModalHeader, ModalBody,
} from "reactstrap"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import API from "../../api"
import { withTranslation } from "react-i18next"
import Select from "react-select"
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator"
import {Link} from "react-router-dom";
import FileDownload from "js-file-download";
const FicheVoeuxSurveillance = props => {

  const [loading, setLoading] = useState(false)
  const [orders, setOrders] = useState([]);

  const [cloture, setCloture] = useState(0);
  const [excel, setExcel] = useState(0);
  var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
  var annee_id = userAuthScolarite.user.annee_id
  const { SearchBar } = Search
  const NoDataIndication = () => (
      <h6 style={{ textAlign: "center" }}>No record to display</h6>
  )
  const pageOptions = {
    loading: true,
    hidePageListOnlyOnePage: true,
    totalSize: orders.length,
    page: 1,
    nextPageText: "Next",
    prePageText: "Back",
    alwaysShowAllBtns: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "All",
        value: orders.length,
      },
    ],
  }
  const Columns = () => [
    {
      dataField: "enseignant",
      text: props.t("Enseignant"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "semestre",
      text: props.t("Semestre"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "departement",
      text: props.t("Département"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "jours",
      text: props.t("Jours"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },

  ]


  useEffect(async () => {
    var cloture_annee = JSON.parse(localStorage.getItem("cloture"));
    setCloture(cloture_annee)
    const userAuth = JSON.parse(localStorage.getItem('UserMenu'));
    const typeArticleSubMenu = userAuth.find(user =>
        user.sous_menu.some(subMenu => subMenu.sous_menu === "Enseignants")
    );

    setExcel(typeArticleSubMenu.sous_menu[0].exel)
    const resL = API.post("fichesurveillance/get", {
      annee_id: annee_id
    }).then(resL => {
      setOrders(resL.data.fiches)
      console.log(resL.data.fiches)
      setLoading(true)
    })
  }, [])
  const ExportExcel = async () => {
    const res = await API.post("fichesurveillance/export", {annee_id},{responseType: "blob"}).then(res => {
      FileDownload(res.data, "Export fiche de surveillance .xlsx")
    })
  }
  return (
      <React.Fragment>
        <div>
          {loading ? (
              <Row>
                <Col xs="12">
                  <ToolkitProvider
                      keyField="id"
                      data={orders}
                      columns={Columns()}
                      search
                      bootstrap4
                  >
                    {toolkitProps => (
                        <div>
                          <div>
                            <div className="mb-3 row" style={{display: "flex"}}>
                              <div className="col-md-6 mb-3 row">
                                <div className="col-md-8 search-box-etd">
                                  <div
                                      className="search-box me-2 mb-2 d-inline-block"
                                      style={{width: "100%"}}
                                  >
                                    <div className="position-relative">
                                      <SearchBar {...toolkitProps.searchProps} />
                                      <i className="bx bx-search-alt search-icon"/>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {excel === 1 ? (
                                  <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                      }}
                                      className="col-md-6 mb-3 row"
                                  >
                                    <div
                                        style={{textAlign: "right"}}
                                        className="col-md-8 select-action"
                                    >
                                      <a
                                          onClick={ExportExcel}

                                          target="_blank"
                                          rel="noreferrer"
                                      >
                                        <button className="btn btn-primary btn btn-primary">
                                          <i className="fas fa-file-download font-size-16 align-middle me-2"></i>
                                          Exportation fiche de surveillance
                                        </button>
                                      </a>
                                    </div>
                                  </div>
                              ) : (null)}
                            </div>

                          </div>
                          <BootstrapTable
                              wrapperClasses="table-responsive"
                              noDataIndication={() => <NoDataIndication/>}
                              striped={false}
                              bordered={false}
                              classes={"table align-middle table-nowrap"}
                              headerWrapperClasses={"table-light"}
                              hover
                              pagination={paginationFactory(pageOptions)}
                              {...toolkitProps.baseProps}
                          />
                        </div>
                    )}
                  </ToolkitProvider>
                </Col>
              </Row>
          ) : (
              <div>
                <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                >
                  {" "}
                  <Spinner type="grow" className="ms-6" color="primary"/>
                </div>
                <h4
                    style={{textAlign: "center", marginTop: "2%"}}
                    className="ms-6"
                >
                  {props.t("Chargement")}
                </h4>
              </div>
          )}
        </div>

      </React.Fragment>
  )
}

export default withTranslation()(FicheVoeuxSurveillance)
FicheVoeuxSurveillance.propTypes = {
  history: PropTypes.object,
  t: PropTypes.any,
}
