import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import API from "../../api"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit"
import {
    Row,
    Col,
    Spinner,
    Modal,
    ModalHeader,
    ModalBody,
    Button, CardTitle, Form, Label,
} from "reactstrap"
import {withRouter} from "react-router"
import {Slide, toast, ToastContainer} from "react-toastify";
import Select from "react-select";

const DataTableCalendrierSurveillanceDetail = props => {
    const [loading, setLoading] = useState(false)
    const [modalDetail, setModalDetail] = useState(false);
    const [id, setId] = useState("")
    const [orders, setOrders] = useState([])
    const [add, setAdd] = useState(0);
    const [edit, setEdit] = useState(0);
    const [delet, setDelet] = useState(0);
    const [cloture, setCloture] = useState(0);
    const [view, setView] = useState(0);
    const [detailExam, setDetailExam] = useState([])
    const [ligne, setLigne] = useState("");
    const pageOptions = {
        loading: true,
        hidePageListOnlyOnePage: true,
        totalSize: orders.length,
        page: 1,
        nextPageText: "Suivant",
        prePageText: "Précédent",
        alwaysShowAllBtns: true,
        sizePerPageList: [
            {
                text: "50",
                value: 50,
            },
            {
                text: "100",
                value: 10,
            },
            {
                text: "150",
                value: 150,
            },
            {
                text: "All",
                value: orders.length,
            },
        ],
    }
    var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
    var annee_id = userAuthScolarite.user.annee_id
    var user_id = userAuthScolarite.user.id
    const NoDataIndication = () => (
        <h6 style={{textAlign: "center"}}>No record to display</h6>
    )
    const Columns = () => [
        {
            dataField: "code",
            text: "CIN ",
            sort: true,
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
        },
        {
            dataField: "nom",
            text: "Nom & prénom",
            sort: true,
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
        },

        {
            dataField: "Detail surveillance",
            isDummyField: true,
            text: "Détail surveillance",
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => (
                cloture !== 1 ? (
                        view === 1 ? (

                            <div style={{display: "flex", justifyContent: "center"}}>
                                <i
                                    style={{color: "#1a9e3a", cursor: "pointer"}}
                                    onClick={() => toggleDetail(row)}
                                    className="fas fa-eye"
                                    title={"Détail surveillance"}
                                ></i>
                            </div>

                        ) : (
                            <div style={{display: "flex", justifyContent: "center"}}>
                                <i
                                    style={{color: "#a7a3a3", cursor: "pointer"}}
                                    className="fas fa-eye"
                                    title="Vous n'avez pas d'accès"
                                ></i>
                            </div>
                        )
                    ) :
                    (
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <i
                                style={{color: "#a7a3a3", cursor: "pointer"}}
                                className="fas fa-eye"
                                title="Année clôturée"
                            ></i>
                        </div>
                    )
            ),
        },
    ]
    const {SearchBar} = Search
    useEffect(async () => {
        var cloture_annee = JSON.parse(localStorage.getItem("cloture"));
        setCloture(cloture_annee)
        const userAuth = JSON.parse(localStorage.getItem('UserMenu'));
        const typeArticleSubMenu = userAuth.find(user =>
            user.sous_menu.some(subMenu => subMenu.sous_menu === "Calendrier de surveillance")
        );
        setAdd(typeArticleSubMenu.sous_menu[0].add)
        setEdit(typeArticleSubMenu.sous_menu[0].edit)
        setDelet(typeArticleSubMenu.sous_menu[0].delete)
        setView(typeArticleSubMenu.sous_menu[0].view)
        const res = await API.post("enseignant/list", {
            annee_id
        }).then(res => {
            setOrders(res.data.enseignants)
        })
        setLoading(true)
    }, [props.semestre])
    const toggleDetail = row => {
        setModalDetail(!modalDetail)
        if(modalDetail !==true){
            setId(row.id)
            setLigne(row)
            API.post("surveillance_examen/consult_ens", {
                enseignant_id: row.id,
                annee_id,
                semestre_id:props.semestre.value
            }).then(res => {
                setDetailExam(res.data.Surveillance)
            })
        }
    }
    useEffect(() => {

    }, [orders])

    return (
        <React.Fragment>
            <div>
                {loading ? (
                    <Row style={{marginTop:"15px"}}>
                        <Col xs="12">
                            <ToolkitProvider
                                keyField="id"
                                data={orders}
                                columns={Columns()}
                                search
                                bootstrap4
                            >
                                {toolkitProps => (
                                    <div>


                                        <Col sm="4">
                                            <div className="search-box me-2 mb-2 d-inline-block">
                                                <div className="position-relative">
                                                    <SearchBar {...toolkitProps.searchProps} />
                                                    <i className="bx bx-search-alt search-icon"/>
                                                </div>
                                            </div>
                                        </Col>
                                        <BootstrapTable
                                            wrapperClasses="table-responsive"
                                            noDataIndication={() => <NoDataIndication/>}
                                            striped={false}
                                            bordered={false}
                                            classes={"table align-middle table-nowrap"}
                                            headerWrapperClasses={"table-light"}
                                            hover
                                            pagination={paginationFactory(pageOptions)}
                                            {...toolkitProps.baseProps}
                                        />
                                    </div>
                                )}
                            </ToolkitProvider>
                        </Col>
                    </Row>
                ) : (
                    <div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            {" "}
                            <Spinner type="grow" className="ms-6" color="primary"/>
                        </div>
                        <h4
                            style={{textAlign: "center", marginTop: "2%"}}
                            className="ms-6"
                        >
                            {" "}
                            En cours ...{" "}
                        </h4>
                    </div>
                )}
            </div>

            <Modal size="xl" isOpen={modalDetail} toggle={toggleDetail} centered={true}>
                <ModalHeader
                    style={{width: "100% !important", textAlign: "center !important"}}
                    toggle={toggleDetail}
                    tag="h4">
                    {"Détail de surveillance pour cette séance d'examen"}
                </ModalHeader>
                <ModalBody>
                    <div
                        style={{
                            fontFamily: "Montserrat, sans-serif",
                            FontSize: "14px",
                            FontWeight: "700",
                            LineHeight: "18.375px",
                        }}
                    >

                        <Row>
                            <div
                                className="mt-4"
                                style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                }}
                            >
                                <div style={{display: "flex"}}>
                                    <p style={{fontWeight: "bold"}}>Enseignant:</p>
                                    <p style={{paddingLeft: "5px", color: "#556ee6"}}>{ligne.nom}</p>
                                </div>
                                <div style={{display: "flex"}}>
                                    <p style={{fontWeight: "bold"}}>Session:</p>
                                    <p style={{paddingLeft: "5px", color: "#556ee6"}}>Principale</p>
                                </div>
                                <div style={{display: "flex"}}>
                                    <p style={{fontWeight: "bold"}}>Semestre:</p>
                                    <p style={{paddingLeft: "5px", color: "#556ee6"}}>{props.semestre.value}</p>
                                </div>
                            </div>
                        </Row>
                        <ModalBody>
                            <table className="table table-bordered mt-4">
                                <thead>
                                <tr>
                                    <th
                                        style={{
                                            textAlign: "center",
                                            border: "2px solid",
                                            backgroundColor: "#95b3d7",
                                        }}
                                    >
                                        Journée
                                    </th>
                                    <th
                                        style={{
                                            textAlign: "center",
                                            border: "2px solid",
                                            backgroundColor: "#95b3d7",
                                        }}
                                    >
                                        Date
                                    </th>
                                    <th
                                        style={{
                                            textAlign: "center",
                                            border: "2px solid",
                                            backgroundColor: "#95b3d7",
                                        }}
                                    >
                                        Horaire
                                    </th>
                                </tr>
                                </thead>
                                {detailExam.map((el, index) => (
                                    <tbody key={index}>
                                    <tr>
                                        <td
                                            style={{
                                                textAlign: "center",
                                                border: "2px solid",
                                                verticalAlign: "middle",
                                            }}
                                            rowSpan={el.details.length + 1}
                                        >
                                            {el.jour}
                                        </td>
                                        <td
                                            style={{
                                                textAlign: "center",
                                                border: "2px solid",
                                                verticalAlign: "middle",
                                            }}
                                            rowSpan={el.details.length + 1}
                                        >
                                            {el.date}
                                        </td>
                                    </tr>
                                    {el.details.map((elem, indice) => (
                                        <tr key={indice}>
                                            <td style={{ textAlign: "center", border: "2px solid" }}>
                                                {elem.quart}
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                ))}
                            </table>
                        </ModalBody>


                    </div>
                </ModalBody>
            </Modal>

            <ToastContainer
                transition={Slide}
                enableMultiContainer
                containerId={"A"}
                position={toast.POSITION.TOP_RIGHT}
                autoClose={2500}
            />
        </React.Fragment>
    )
}


export default withRouter(DataTableCalendrierSurveillanceDetail)
DataTableCalendrierSurveillanceDetail.propTypes = {
    orders: PropTypes.array,
    history: PropTypes.object,
    semestre: PropTypes.object,
}
