import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import API from "../../api"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit"
import {
    Row,
    Col,
    Spinner,
    Modal,
    ModalHeader,
    ModalBody,
    Button, CardTitle, Label, Form,
} from "reactstrap"
import {withRouter} from "react-router"
import {Link} from "react-router-dom"
import Select from "react-select";
import {Slide, toast, ToastContainer} from "react-toastify";

const DataTableGroupeExamen = props => {
    const [loading, setLoading] = useState(false)
    const [modal, setModal] = useState(false)
    const [id, setId] = useState("")
    // Data Table
    const [orders, setOrders] = useState([])
    const [add, setAdd] = useState(0);
    const [edit, setEdit] = useState(0);
    const [delet, setDelet] = useState(0);
    const [cloture, setCloture] = useState(0);
    const [semestre, setSemestre] = useState([
        { value: 1, label: "Semestre 1" },
        { value: 2, label: "Semestre 2" },
    ])
    const [selectSemestre, setSelectSemestre] = useState("")
    const [niveau, setNiveau] = useState([])
    const [selectNiveau, setSelectNiveau] = useState("")
    const [specialite, setSpecialite] = useState([])
    const [selectSpecialite, setSelectSpecialite] = useState("")
    const [modalDeleteGroup, setModalDeleteGroup] = useState(false);
    const pageOptions = {
        loading: true,
        hidePageListOnlyOnePage: true,
        totalSize: orders.length,
        page: 1,
        nextPageText: "Suivant",
        prePageText: "Précédent",
        alwaysShowAllBtns: true,
        sizePerPageList: [
            {
                text: "50",
                value: 50,
            },
            {
                text: "100",
                value: 10,
            },
            {
                text: "150",
                value: 150,
            },
            {
                text: "All",
                value: orders.length,
            },
        ],
    }
    var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
    var annee_id = userAuthScolarite.user.annee_id
    var user_id = userAuthScolarite.user.id
    const NoDataIndication = () => (
        <h6 style={{textAlign: "center"}}>No record to display</h6>
    )
    const Columns = () => [
        {
            dataField: "nom",
            text: "Nom du groupe",
            sort: true,
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
        },
        {
            dataField: "capacite",
            text: "Capacité",
            sort: true,
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
        },
        {
            dataField: "semestre",
            text: "Semestre",
            sort: true,
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
        },
        {
            dataField: "name_niveau",
            text: "Niveau",
            sort: true,
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
        },
        {
            dataField: "name_specialite",
            text: "Spécialite",
            sort: true,
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
        },
        {
            dataField: "salle",
            text: "Salle",
            sort: true,
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
        }
    ]

    const {SearchBar} = Search

    useEffect(async () => {
        var cloture_annee = JSON.parse(localStorage.getItem("cloture"));
        setCloture(cloture_annee)
        const userAuth = JSON.parse(localStorage.getItem('UserMenu'));
        const typeArticleSubMenu = userAuth.find(user =>
            user.sous_menu.some(subMenu => subMenu.sous_menu === "Groupe d'examen")
        );
        setAdd(typeArticleSubMenu.sous_menu[0].add)
        setEdit(typeArticleSubMenu.sous_menu[0].edit)
        setDelet(typeArticleSubMenu.sous_menu[0].delete)
        const res = await API.post("groupe_examen/liste",{annee_id}).then(res => {
            setOrders(res.data.groupes)
        })
        const resNiveau = await API.get("niveau/select").then(resNiveau => {
            setNiveau(resNiveau.data.Niveau)
        })
        setLoading(true)
    }, [])

    const toggle = id => {
        setModal(!modal)
        setId(id)
    }
    const toggleDeleteGroup = id => {
        if (selectSemestre === "" && selectNiveau === "" && selectSpecialite === "") {
            toast.error("⛔ Tous les champs sont obligatoire !", {
                containerId: "A",
            })
        }else{
            setModalDeleteGroup(!modalDeleteGroup)
            setId(id)
        }
    }

    const toggleDelete = async () => {

        const res = await API.post("salle/delete", {
            id: id,
        }).then(res => {
            const resD = API.get("salle/list").then(resD => {
                setOrders(resD.data.Salle)
            })
            setLoading(true)
        })
    }
    const toggleDeleteGroupExamen = async () => {
        setModalDeleteGroup(false)
        const res = await API.post("groupe_examen/destroy_by_spec_niv_sem", {
            specialite_id: selectSpecialite.value,
            niveau_id:selectNiveau.value,
            semestre_id:selectSemestre.value,
            user_id,
            annee_id
        }).then(res => {
            if(res.data.errNum === 301 || res.data.errNum ==="301"){
                toast.error("⛔ "+res.data.msg, {
                    containerId: "A",
                })
            }else{
                const resD = API.post("groupe_examen/liste",{annee_id}).then(resD => {
                    setOrders(resD.data.groupes)
                })
                setLoading(true)
            }

        })
    }

    useEffect(() => {
        setModal(false)
    }, [orders])
    const getSpecialite = async event => {
        setSelectNiveau(event)
        const res = await API.post("group/get_list_spec", {
            niveau_id: event.value,
            semestre_id: [selectSemestre],
        }).then(res => {
            setSpecialite(res.data.Groups)
        })
    }
    const search = async () => {
        if (selectSemestre !== "" && selectNiveau !== "" && selectSpecialite !=="") {
            setOrders([])
            const res = await API.post("groupe_examen/filter", {
                specialite_id: selectSpecialite.value,
                niveau_id:selectNiveau.value,
                semestre_id:selectSemestre.value,
                annee_id
            }).then(res => {
                setOrders(res.data.groupes)
            })
        } else {
            toast.error("⛔ Tous les champs sont obligatroires !", {
                containerId: "A",
            })
        }
    }
    const searchAll = async () => {
        setSelectNiveau("")
        setSelectSpecialite("")
        setSelectSemestre("")
        const resD = API.post("groupe_examen/liste",{annee_id}).then(resD => {
            setOrders(resD.data.groupes)
        })
        setLoading(true)
    }
    return (
        <React.Fragment>
            <div>
                {loading ? (
                    <Row>
                        <Col xs="12">
                            <ToolkitProvider
                                keyField="id"
                                data={orders}
                                columns={Columns()}
                                search
                                bootstrap4
                            >
                                {toolkitProps => (
                                    <div>
                                        <CardTitle style={{color: "#556ee6"}} className="h4">
                                            Critéres de Recherches
                                        </CardTitle>
                                        <Form className="mt-4">
                                            <Row>
                                                <Col md={3}>
                                                    <div className="mb-3">
                                                        <Label for="basicpill-firstname-input1">Semestre:</Label>
                                                        <Select
                                                            options={semestre}
                                                            isSearchable={true}
                                                            value={selectSemestre}
                                                            onChange={e => setSelectSemestre(e)}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col lg="3">
                                                    <div className="mb-3">
                                                        <Label for="basicpill-firstname-input1">Niveau</Label>
                                                        <Select
                                                            options={niveau}
                                                            isSearchable={true}
                                                            value={selectNiveau}
                                                            onChange={e => getSpecialite(e)}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col lg="6">
                                                    <div className="mb-3">
                                                        <Label for="basicpill-firstname-input1">Spécialité</Label>
                                                        <Select
                                                            options={specialite}
                                                            value={selectSpecialite}
                                                            isSearchable={true}
                                                            onChange={e => setSelectSpecialite(e)}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                        <div style={{display: "flex", justifyContent: "center"}}>

                                            <Col lg="12">
                                                <div className="text-center mt-4">
                                                    <button
                                                        type="button"
                                                        style={{
                                                            backgroundColor: "#761C19",
                                                            borderColor: "#761C19",
                                                        }}
                                                        className="btn btn-success mb-2 me-2"
                                                        onClick={search}
                                                    >
                                                        Rechercher
                                                    </button>
                                                    <button
                                                        type="button"
                                                        style={{
                                                            backgroundColor: "#761C19",
                                                            borderColor: "#761C19",
                                                        }}
                                                        className="btn btn-success mb-2 me-2"
                                                        onClick={searchAll}
                                                    >
                                                        Afficher tout
                                                    </button>
                                                    {delet === 1 ? (
                                                        <button
                                                            type="button"
                                                            style={{
                                                                backgroundColor: "#796102",
                                                                borderColor: "#796102",
                                                            }}
                                                            className="btn btn-success mb-2 me-2"
                                                            onClick={toggleDeleteGroup}>
                                                            {"Supprimer groupe d'examen"}
                                                        </button>
                                                    ):null}

                                                </div>
                                            </Col>

                                        </div>
                                        <Col sm="4">
                                            <div className="search-box me-2 mb-2 d-inline-block">
                                                <div className="position-relative">
                                                    <SearchBar {...toolkitProps.searchProps} />
                                                    <i className="bx bx-search-alt search-icon"/>
                                                </div>
                                            </div>
                                        </Col>
                                        <BootstrapTable
                                            wrapperClasses="table-responsive"
                                            noDataIndication={() => <NoDataIndication/>}
                                            striped={false}
                                            bordered={false}
                                            classes={"table align-middle table-nowrap"}
                                            headerWrapperClasses={"table-light"}
                                            hover
                                            pagination={paginationFactory(pageOptions)}
                                            {...toolkitProps.baseProps}
                                        />
                                    </div>
                                )}
                            </ToolkitProvider>
                        </Col>
                    </Row>
                ) : (
                    <div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            {" "}
                            <Spinner type="grow" className="ms-6" color="primary"/>
                        </div>
                        <h4
                            style={{textAlign: "center", marginTop: "2%"}}
                            className="ms-6"
                        >
                            {" "}
                            En cours ...{" "}
                        </h4>
                    </div>
                )}
            </div>
            <Modal isOpen={modal} toggle={toggle} centered={true}>
                <ModalHeader
                    style={{width: "100% !important", textAlign: "center !important"}}
                    toggle={toggle}
                    tag="h4"
                >
                    {"Suppression d'une salle"}{" "}
                </ModalHeader>
                <ModalBody>
                    <div
                        style={{
                            fontFamily: "Montserrat, sans-serif",
                            FontSize: "14px",
                            FontWeight: "700",
                            LineHeight: "18.375px",
                        }}
                    >
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <p>Voulez-vous supprimer cette Salle ?</p>
                        </div>
                        <div
                            className="hvr-push"
                            style={{
                                display: "flex",
                                justifyContent: "space-around",
                                marginBottom: "15px",
                            }}
                        >
                            <div>
                                <Button
                                    color="info"
                                    className="btn-rounded "
                                    onClick={toggleDelete}
                                >
                                    <i
                                        style={{color: "white"}}
                                        className="far fa-thumbs-up"
                                    ></i>
                                    Oui
                                </Button>
                            </div>
                            <div>
                                <Button
                                    onClick={toggle}
                                    color="danger"
                                    className="btn-rounded "
                                >
                                    <i
                                        style={{color: "white"}}
                                        className="far fa-thumbs-down"
                                    ></i>
                                    Non
                                </Button>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
            <Modal isOpen={modalDeleteGroup} toggle={toggleDeleteGroup} centered={true}>
                <ModalHeader
                    style={{width: "100% !important", textAlign: "center !important"}}
                    toggle={toggleDeleteGroup}
                    tag="h4"
                >
                    {"Suppression d'un groupe d'examen"}
                </ModalHeader>
                <ModalBody>
                    <div
                        style={{
                            fontFamily: "Montserrat, sans-serif",
                            FontSize: "14px",
                            FontWeight: "700",
                            LineHeight: "18.375px",
                        }}
                    >
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <p>{"Voulez - vous supprimer cette groupe d'examen ?"}</p>
                        </div>
                        <div
                            className="hvr-push"
                            style={{
                                display: "flex",
                                justifyContent: "space-around",
                                marginBottom: "15px",
                            }}
                        >
                            <div>
                                <Button
                                    color="info"
                                    className="btn-rounded "
                                    onClick={toggleDeleteGroupExamen}
                                >
                                    <i
                                        style={{color: "white"}}
                                        className="far fa-thumbs-up"
                                    ></i>
                                    Oui
                                </Button>
                            </div>
                            <div>
                                <Button
                                    onClick={toggleDeleteGroup}
                                    color="danger"
                                    className="btn-rounded "
                                >
                                    <i
                                        style={{color: "white"}}
                                        className="far fa-thumbs-down"
                                    ></i>
                                    Non
                                </Button>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
            <ToastContainer
                transition={Slide}
                enableMultiContainer
                containerId={"A"}
                position={toast.POSITION.TOP_RIGHT}
                autoClose={2500}
            />
        </React.Fragment>
    )
}

export default withRouter(DataTableGroupeExamen)
DataTableGroupeExamen.propTypes = {
    orders: PropTypes.array,
    history: PropTypes.object,
}
